import React, {createRef, useEffect} from "react";
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_processes, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import {GuideMessage} from "views/pages/components/GuideMessage";
import QRCode from "react-qr-code";
import ModalInviteUser from "../Modal/ModalInviteUser";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Panel } from 'primereact/panel';

export class Elements extends React.Component {
  static contextType = UserContext
  state={
    data_traza:[],
    trazabilidad_meat:[],
    company_id:null,
    jsondataBase:null,
    sendOpenModal:false,
    coder:'none',
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
    visibleRight:false,
    visibleRightTwo:false,
    code:null,
    codeSave:null
  }
 async componentDidMount(){
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (this.props.sendDataCompany.type_traza==null) {
    jQuery('#tm').remove()
    jQuery('#tvf').remove()
    jQuery('#tvfkdes').remove()
    return
  }else{
    // if (this.props.sendDataCompany.type_traza=="VF") {
    //   jQuery('#tm').remove()
    // }
    // if (this.props.sendDataCompany.type_traza=="M") {
    //   jQuery('#tvf').remove()
    // }
    if (this.props.sendDataCompany.type_traza.indexOf('VF')==-1) {
      jQuery('#tvf').remove()
      jQuery('#tvfkdes').remove()
    }
    if (this.props.sendDataCompany.type_traza.indexOf('M')==-1) {
      jQuery('#tm').remove()
    }

  }
  // || this.props.sendDataCompany.type_traza=="VF-M"
  var data = await data_list_processes(jsondata._id,this.props.sendDataCompany.user_id_connection,this.props.sendDataCompany.type,'ttp',jsondata.userType)
  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  // if (data.trazabilidad[1].length!=0) {
  //   data.trazabilidad[1].map((a,b)=>{
  //     data.trazabilidad[0].map((val,ind)=>{
  //     console.log(b)

  //       //console.log(a[val.split('*')[1]])
  //     })
  //   })
  // }
  this.setState({
    company_id:gerParam,

    data_traza:data.trazabilidad[1],
    data_traza_th:data.trazabilidad[0],

    data_traza_kdes:data.trazabilidad_kdes[1],
    data_traza_kdes_th:data.trazabilidad_kdes[0],

    data_traza_g:data.traza_grain[1],
    data_traza_g_th:data.traza_grain[0],
    trazabilidad_meat:data.trazabilidad_meat,
    jsondataBase:jsondata.database
  })
  }
  logictRouteParam=()=>{
      var routeParamNameFV = 'start-trace/vegetables-fruits'
      if (this.props.sendDataCompany.role==2) {
        switch (1) {
          case this.props.sendDataCompany.trazabilidad:
            routeParamNameFV='start-trace/vegetables-fruits';
            break;
          case this.props.sendDataCompany.produccion:
            routeParamNameFV='fruits-vegetables/production';
            break;
          case this.props.sendDataCompany.cosecha:
            routeParamNameFV='fruits-vegetables/harvest';
            break;
          case this.props.sendDataCompany.planta:
            routeParamNameFV='fruits-vegetables/plant';
            break;
          case this.props.sendDataCompany.logistica:
            routeParamNameFV='fruits-vegetables/logistics';
            break;
          case this.props.sendDataCompany.documento:
            routeParamNameFV='docs';
            break;
          case this.props.sendDataCompany.comercializa:
            routeParamNameFV='fruits-vegetables/Comercialization';
            break;
          default:
            routeParamNameFV='start-trace/vegetables-fruits';
        }
      }
      var routeParamNameGrain = 'info_crops_t'
      if (this.props.sendDataCompany.role==2) {
        switch (1) {
          case this.props.sendDataCompany.information_grain_t:
            routeParamNameGrain='info_crops_t';
            break;
          case this.props.sendDataCompany.production_grain_t:
            routeParamNameGrain='grain_seed_t';
            break;
          case this.props.sendDataCompany.harvest_grain_t:
            routeParamNameGrain='grain_harvest_info_harvest_t';
            break;
          case this.props.sendDataCompany.document_grain_t:
            routeParamNameGrain='grains_traza_documents';
            break;
          default:
            routeParamNameGrain='info_crops_t';
        }
      }
    return {routeParamNameGrain,routeParamNameFV}
  }
  setContent = (data,typeT) => {
    if (data==undefined) {
      return
    }
    if (data!=undefined) {
      if (data.length!=0) {
      var content=[]
        data.map((a,b)=>{
           var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            var name_title = ''
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/grains-nama-info_crop"
              }
              if (val[1]?.split('-')[0]=='FCTGT') {
                url_selected = "/admin/"+this.logictRouteParam().routeParamNameGrain
              }
              if (val[1]?.split('-')[0]=='FCT') {
               url_selected = "/admin/"+this.logictRouteParam().routeParamNameFV
              }
              if (val[1]?.split('-')[0]=='FCTK') {
               url_selected = "/admin/fruit_vegetable_user"
               name_title='Frutas y Vegetales KDEs'
               //+this.logictRouteParam().routeParamNameFVK
              }
            }
            // var getCodeToken = ''
            // if (val[0]=='token_id') {
            //     getCodeToken=Object.entries(a)[Object.entries(a).length-1][1]
            // }
   var content_two = <>
    
    {(val[0]!='codigo' && val[0]!='token_id')?(<td><span className="text-muted">{val[1]}</span></td>):(null)}
    {(val[0]=='codigo')?(<><td><span className="text-muted">{val[1]}</span></td><td>
                  <ComponentLink
                   className="font-weight-bold"
                                         to={{
                     pathname:url_selected,
                     search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                     state:{
                       codigoTraza:val[1],
                       title_name: name_title
                     }
                     }}
                   >
                  Ver Esta Trazabilidad
                  </ComponentLink>
                </td>
                <td><QRCode style={{ width: "65px", height: 'auto' }} value={"http://foodchaintrazabilidad.com/home/vista-trace?code="+val[1]+"&db="+this.state.jsondataBase}  /></td>
                <td>
                <button 
                className="btn" 
                title="Compartir con usuario" 
                onClick={()=>{
                  this.setInfoInvoice(true, val[1],  Object.entries(a)[Object.entries(a).length-1][1])
                }}> <span className="fas fa-share-alt"></span> </button></td>
                </>):(null)}

                {(val[0]=='token_id')?(
                  (val[1]!=null)?(<>
                      <td>
                        <button className="btn" title="Click para copiar el token generado para esta trazabilidad" 
                        onMouseOut={()=>{
                          if (jQuery('.changeIconClip').hasClass('fa-check')) {
                            jQuery('.changeIconClip').removeClass('fas fa-check')
                            jQuery('.changeIconClip').addClass('far fa-copy')
                          }
                        }}
                        onClick={(copyText)=>{
                          // copyText.target.select();
                          // copyText.target.setSelectionRange(0, 99999);
                          navigator.clipboard.writeText(val[1]);
                          jQuery('.changeIconClip').removeClass('far fa-copy')
                          jQuery('.changeIconClip').addClass('fas fa-check')
                        }}> <span className="far fa-copy changeIconClip"></span> </button>
                      </td>
                      {(typeT=='KDES')?(<>
                          <td>
                            <button className="btn" title="Click para ver vista trace" 
                            onClick={()=>{
                              this.props.history.push({
                                                      pathname: "/admin/vista-trace-kdes-admin",
                                                      search: '?code='+val[1]
                                                    })
                            }}> <span className="far fa-eye"></span> </button>
                          </td>
                        </>):(null)}
                    </>):(<td><span className="text-muted">Sin token</span></td>)
                  ):(null)}
                        </>
                        return content_two
          })
          content[b] = <tr>{content_one}</tr>
        })
      }
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      let uniqueChars = [...new Set(data)];
      Object.values(uniqueChars).map((a,b)=>{
        content[b]= {title:a.split('*')[0],table:a.split('*')[1]}
      })
    }
    return content
  }
  setInfoInvoice=(changeModal,code,token)=>{
    this.setState({sendOpenModal:changeModal,'coder':code+'|'+token})
  }
render(){
    const onGlobalFilterChange = (valueinp) => {
        const value = valueinp;
        let _filters = { ...this.state.filters };

        _filters['global'].value = value;

        //setFilters(_filters);
        this.setState({filters:_filters})
        this.setState({globalFilter:valueinp})
    };
    const header = (
        <div className="flex flex-column md:flex-row flex-md-row md:justify-content-between md:align-items-center">
            <h5 className="m-0" style={{ color: '#002a5c' }}>
                Gestionar trazabilidad
            </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText 
                type="search" 
                value={this.state.globalFilter}
                //onInput={(e) => this.setState({globalFilter:e.currentTarget.value}) }
                onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                placeholder="Buscar..." 
                />
            </span>

{/*            <Button 
                label="Nueva" 
                icon="pi pi-plus" 
                severity="success" 
                className="btn-fod-green mr-2" 
                onClick={() => this.setState({visibleRight:true})} 
                />*/}
        </div>
    );
    const actionBodyTemplate = (rowData) => {

            var url_selected = null
            var name_title = ''

              if (rowData.codigo?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/grains-nama-info_crop"
              }
              if (rowData.codigo?.split('-')[0]=='FCTGT') {
                url_selected = "/admin/"+this.logictRouteParam().routeParamNameGrain
              }
              if (rowData.codigo?.split('-')[0]=='FCT') {
               url_selected = "/admin/"+this.logictRouteParam().routeParamNameFV
              }
              if (rowData.codigo?.split('-')[0]=='FCTK') {
               url_selected = "/admin/panel/fruit_vegetable_user"
               name_title='Frutas y Vegetales KDEs'
               //+this.logictRouteParam().routeParamNameFVK
              }

        return (
            <>
                <Button 
                    icon="pi pi-cog" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    onClick={ () => {
                      this.props.history.push( url_selected+( (this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo) ) )
                    }}
                    tooltip="Ver esta trazabilidad" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                />

                <Button 
                    icon="pi pi-share-alt" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    onClick={ () => {
                      this.setInfoInvoice(true, rowData.codigo,  rowData.token_id)
                      //this.setState({visibleRightTwo:true, code:rowData.codigo})
                      //onClick={()=>{this.setInfoInvoice(true, val[1],  Object.entries(a)[Object.entries(a).length-1][1])}}
                      //this.props.history.push( '/admin/prouction-observations-mvr'+((this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo)) )
                    }}
                    tooltip="Invitar/Enviar" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }} 
                />

                <Button 
                    icon="pi pi-copy" 
                    rounded 
                    outlined 
                    className={"mr-2 button-fod changeIconClip"+rowData.id}
                    onMouseOut={()=>{
                      //pi-check
                      if (jQuery('.changeIconClip'+rowData.id).hasClass('pi-check')) {
                        jQuery('.changeIconClip'+rowData.id).removeClass('pi pi-check')
                        jQuery('.changeIconClip'+rowData.id).addClass('pi pi-copy')
                      }
                    }}
                    onClick={ () => {
                          navigator.clipboard.writeText(rowData.token_id);
                          jQuery('.changeIconClip').removeClass('pi pi-copy')
                          jQuery('.changeIconClip').addClass('pi pi-check')
                    }}
                    tooltip="Copiar token" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }} 
                />

                <Button 
                    icon="pi pi-eye" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    onClick={ () => {
                      this.props.history.push({
                                              pathname: "/admin/vista-trace-kdes-admin",
                                              search: '?code='+rowData.token_id
                                            })
                    }}
                    tooltip="Vista trace" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }} 
                />

            </>
        );
    };
  return (
    <>
     <ModalInviteUser 
       sendOpenModal={this.state.sendOpenModal}
       setInfoInvoice={this.setInfoInvoice}
       setCode={this.state.coder}
       contexto={this.context}
     /> 
      {/*<SimpleHeader name="Trazabilidad en proceso" parentName="Trazabilidad en proceso" section="fuits_vegetable" section_two="Tabla" section_table_form="table_traza_proccess" />*/}
        <Container className="mt-2" fluid>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card card-table">
                    {/*<Toast ref={toast} />*/}

                    <DataTable
                        value={this.state.data_traza_kdes}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                        filters={this.state.filters}
                        className="datatable-responsive"
                        emptyMessage="Sin registros encontrados."
                        responsiveLayout="scroll"
                        header={header}
                    >
                        {this.setContentTh(this.state.data_traza_kdes_th).map((a,b)=>{
                          return <Column field={a.table} header={a.title} ></Column>
                        })}
                        <Column field="codigo" header="Código" sortable></Column>
                        <Column field="created_at" header="Creado" sortable></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                    </DataTable>

    
                </div>
            </div>
        </div>
      </Container>


{/*        <Container className="mt--6" fluid>
          <Row>
          <Col md={12}>

            <Card id="tvf" style={{display:(this.state.data_traza==undefined)?('none'):((this.state.data_traza[0]==undefined)?('none'):('block'))}}>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Trazabilidad Frutas y vegetales</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>id</th>
                  {this.setContentTh(this.state.data_traza_th)}
                    <th>Creado</th>
                    <th>Código</th>
                    <th>Opciones</th>
                    <th>QR</th>
                    <th>Invitar/Enviar</th>
                    <th>Token</th>
                  </tr>
                </thead>
                <tbody>
                    {this.setContent(this.state.data_traza,'VF')}
                </tbody>
              </Table>
            </Card>

            <Card id="tvfkdes" style={{display:(this.state.data_traza_kdes==undefined)?('none'):((this.state.data_traza_kdes[0]==undefined)?('none'):('block'))}}>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Trazabilidad Frutas y vegetales KDEs</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>id</th>
                  {this.setContentTh(this.state.data_traza_kdes_th)}
                    <th>Creado</th>
                    <th>Código</th>
                    <th>Opciones</th>
                    <th>QR</th>
                    <th>Invitar/Enviar</th>
                    <th>Token</th>
                    <th>Ver proceso</th>
                  </tr>
                </thead>
                <tbody>
                    {this.setContent(this.state.data_traza_kdes,'KDES')}
                </tbody>
              </Table>
            </Card>

            <Card id="tm" style={{display:(this.state.trazabilidad_meat[0]==undefined)?('none'):('block')}}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Trazabilidad Carnicos</h3>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>id</th>
                  <th>especie</th>
                  <th>Creado</th>
                  <th>Trazabilidad Código</th>
                  <th>Nombre de la trazabilidad</th>
                  <th>Lote</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
              {this.state.trazabilidad_meat.map((value,index)=>{
                return(
                  <tr>
                    <td>
                      <span className="text-muted">
                      {value.id}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.especie}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.created_at}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.codigo}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.nombre_traza}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.lote_numero}
                      </span>
                    </td>
                    <td>
                      <ComponentLink
                        className="font-weight-bold"
                                              to={{
                          pathname:"/admin/meats/job-lote",
                          search: (this.state.company_id==null)?("?code="+value.codigo+'&lote='+value.lote_numero):("?company="+this.state.company_id+"&code="+value.codigo+'&lote='+value.lote_numero),
                          state:{
                            codigoTraza:value.codigo
                          }
                          }}
                        >
                      Ver Esta Trazabilidad
                      </ComponentLink>
                    </td>
                  </tr>)
              })
            }

              </tbody>
            </Table>
          </Card>
        <Card id="tg" style={{display:(this.state.data_traza_g==undefined)?('none'):((this.state.data_traza_g[0]==undefined)?('none'):('block'))}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Grano: Trazabilidad arroz</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>id</th>
              {this.setContentTh(this.state.data_traza_g_th)}
                <th>Creado</th>
                <th>Código</th>
                <th>Opciones</th>
                <th>QR</th>
              </tr>
            </thead>
            <tbody>
                {this.setContent(this.state.data_traza_g,"M")}
            </tbody>
          </Table>
        </Card>
        </Col>
        </Row>
      </Container>*/}
      <br/><br/>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false,
  }
 async componentDidMount(){
    this.context.getAffiliates()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    var data_type_area = dataPermission.type_traza.indexOf('T')
        if (data_type_area==-1) {
          this.setState({get_permission:false})
          return;
        }
          this.setState({company_id_act:dataPermission})
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Elements {...this.props} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
