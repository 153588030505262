import React from "react";
import classnames from "classnames";
import { logout, change_notifications } from "network/ApiAxios";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container
} from "reactstrap";
import config from "config";
import * as firedatabase from "firebase/database";
import { db } from "network/ConfigFirebase";
import {UserContext} from "layouts/store";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "network/ConfigFirebase";
//function AdminNavbar(prop) {
  class AdminNavbar extends React.Component {
    static contextType = UserContext
    state={
      user_permission:null,
      type_traza:null
    }
    async componentDidMount(){
    // await onMessage(messaging, (payload) => {
    //   console.log(payload)
    // });
    var userdata = await localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({user_permission:jsondata.userType})
    this.setState({type_traza:jsondata.type_traza})
    }
   logOut = async () => {
    const token = localStorage.getItem("token");
    if (token) {
        const response = await logout(token);
        const { data } = response;
        if (data.success) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          this.props.historySend.push("/auth/login");
          return true
        }
    }
  }    

 // logOutSessionFire = () => {
 //    if (this.logOut()) {
 //              this.functionFBCreate()
 //    }
 //  }
 logOutSessionFire = () => {
           firedatabase.get(this.getDBFire()).then((snapshot) => {
            if (snapshot.exists()) {
               var data = snapshot.val();
               var dataKey = Object.keys(data);
               var getData = Object.values(data)
               if (getData[getData.length-1].active==1) {
                 var startDate = new Date(getData[getData.length-1].close_sessions_date)
                 var endDate = new Date()
                 var difference = endDate.getTime() - startDate.getTime();
                 var resultInMinutes = Math.round(difference / 60000);
                   this.functionFBEdit(String(dataKey[dataKey.length-1]),getData[getData.length-1],0)
               }else{
              this.functionFBCreate()
              }
            }else{
              this.functionFBCreate()
            }
        });
       var dataGlo = {userId:null,location:'logout'}
       if (window.ReactNativeWebView!=undefined) {
        window.ReactNativeWebView.postMessage(JSON.stringify(dataGlo));
       }
  }
getUserId = () => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  return jsondata._id
}
getDBFire = () => {
const productsCollection = firedatabase.ref(db,'user/'+this.getUserId())
return productsCollection
};
getDateString = () => {
      var date = new Date();
      var completeDate = date.toLocaleDateString("en-CA")
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1]+':'+String(date).split(":")[2].split(" ")[0];
      var globalTimeDate = completeDate+' '+timeString
      return globalTimeDate
};
 functionFBCreate = () => {
             const newPostRef = firedatabase.push(this.getDBFire());
             firedatabase.set(newPostRef,{
                user_id: this.getUserId(),
                close_sessions_date: String(this.getDateString()),
                active:0,
              }).catch(alert);
             this.logOut()
  };
 functionFBEdit = async (indexKey,data,activ) => {
  const postData = {
    user_id: this.getUserId(),
    close_sessions_date: (activ==1)?(this.getDateString()):(data.close_sessions_date),
    active: activ
  };
    var updates = {};
    updates[indexKey] = postData;    
    firedatabase.update(this.getDBFire(), updates)
    this.logOut()
  };
   getInfoGlobal = () => {
    var dataGet = this.context.valueGlobal
    return dataGet
  };

render(){
  //const history = useHistory();
  let {name: username} = JSON.parse(localStorage.getItem("user"));
  const photo_profile = localStorage.getItem("photo_profile");
  const user = JSON.parse(localStorage.getItem("user")).email.split("@")[0];
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  const goProfile = async () => {
    this.props.historySend.push("/admin/profile");
  }
  const goWallet = async () => {
    this.props.historySend.push("/admin/wallet");
  }
  const goCompany = async () => {
    this.props.historySend.push("/admin/company");
  }
  const pushNotifications = async () =>{
    this.context.setvalueCountNotifi(0)
    this.context.getAffiliates()
    this.props.historySend.push("/admin/notifications");
    var result = await change_notifications(this.getUserId())
  }
  const redirectAreaWork =(type)=>{
    if (type=='global') {
      localStorage.removeItem('type_area_work')
    }else{    
      localStorage.setItem('type_area_work', type)
    }
      window.location.reload()
  }
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-traza-grayblue": this.props.theme === "dark" },
          { "navbar-light bg-secondary": this.props.theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": this.props.theme === "dark" },
                { "navbar-search-dark": this.props.theme === "light" }
              )}
            >
              
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form>

            <Nav className="align-items-center ml-md-auto" navbar>
            <a href="#" onClick={()=>{pushNotifications()}}>
              <i style={{color: 'white', fontSize: '20px'}} className="fa fa-bell">
                <label id="counternoti" style={{margin:0,position: 'relative',color: 'red',fontSize: '12px',bottom: '10px'}}>{this.context.valueCountNotifi}</label>
              </i>
            </a>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: this.props.sidenavOpen },
                    { "sidenav-toggler-dark": this.props.theme === "dark" }
                  )}
                  onClick={this.props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span style={{backgroundColor:'white'}} className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={(photo_profile=='null')?(require("assets/img/theme/profile.jpg").default):(config.URL_PRODUCTION+"/storage/file_documents/"+photo_profile)}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {username}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{user}</h6>
                  </DropdownItem>
                  <DropdownItem
                    onClick={goProfile}
                  >
                    <i className="fas fa-user" />
                    <span>Mi perfil</span>
                  </DropdownItem>
                  {(this.state.user_permission==1)?(
                      <DropdownItem
                        onClick={goWallet}
                      >
                        <i className="fas fa-wallet" />
                        <span>Mi Billetera</span>
                      </DropdownItem>
                    ):(null)}

{/*                  <DropdownItem
                    onClick={goCompany}
                  >
                    <i className="fas fa-building" />
                    <span>Compañia</span>
                  </DropdownItem>*/}
                  {(this.state.user_permission==1)?(<>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={()=>{redirectAreaWork('global')}}
                  >
                    <i className="fas fa-star" />
                    <span>Dashboard</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  {(this.state.type_traza?.indexOf('P')!=-1)?(
                  <DropdownItem
                    onClick={()=>{redirectAreaWork('production')}}
                  >
                    <i className="fas fa-tractor" />
                    <span>Producción</span>
                  </DropdownItem>
                    ):(null)}
                  {(this.state.type_traza?.indexOf('T')!=-1)?(
                    <DropdownItem
                      onClick={()=>{redirectAreaWork('trazability')}}
                    >
                      <i className="fas fa-map" />
                      <span>Trazabilidad</span>
                    </DropdownItem>
                    ):(null)}
                  {(this.state.type_traza?.indexOf('C')!=-1)?(
                  <DropdownItem
                    onClick={()=>{redirectAreaWork('commerce')}}
                  >
                    <i className="fas fa-store" />
                    <span>Comercialización</span>
                  </DropdownItem>
                    ):(null)}

                    </>):(null)}

                  <DropdownItem divider />
                  <DropdownItem
                    onClick={()=> {
                      this.logOutSessionFire()
                    } }
                  >
                    <i className="fas fa-sign-out-alt" />
                    <span>Cerrar sesión</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
 }  
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
