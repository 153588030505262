import React from 'react';
import { Sidebar } from 'primereact/sidebar';

const SidebarCustom = ({ children, onVisible, onHide, onWidth, title, position }) => {
    const customIcons = (
        <React.Fragment>
            <button className="p-sidebar-icon p-link mr-2">
                <span className="pi pi-search" />
            </button>
        </React.Fragment>
    );

    const customHeader = (
        <div className="flex align-items-center gap-2 ">
            <span 
            style={{ fontWeight: 'normal', color: '#7ac142' }} 
            className="font-semibold text-2xl uppercase">{title}</span>
         
        </div>
    );

    return (
        <div>
            <Sidebar  
                className="animation-duration-500 shadow-3" 
                header={customHeader} 
                visible={onVisible} 
                style={{ width: (onWidth) ? onWidth : null }}
                onHide={() => onHide()} 
                position={position}>
                
                { children }
            </Sidebar>

        </div>
    );
};

export default SidebarCustom;
