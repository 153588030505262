import React,{useContext} from "react";
import classnames from "classnames";
import { Chart } from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Badge,
  //Button,
  //Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import HomeCardsHeader from "components/Headers/HomeCardsHeader.js";
import CardsHeader from "components/Headers/CardsHeader.js";
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import { NavLink as ComponentLink } from "react-router-dom";
import {UserContext} from "layouts/store";
import { Button } from 'primereact/button';
import ProductionTable from "views/pages/components/ProductionTable";
import TraceTableKdes from "views/pages/components/TraceTableKdes";
import TraceTable from "views/pages/components/TraceTable";
import AlertMRV from "components/Headers/AlertMRVOR.js";
import './styleHome.css';
import { Tooltip } from 'primereact/tooltip';

function Dashboard(props) {
  const data_tp = useContext(UserContext).valueGlobal
  const type_prod = data_tp.type_traza
  const [activeNav, setActiveNav] = React.useState(1);
  const [nextOption, setNextOption] = React.useState(false);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const [forModal, setForModal] = React.useState(false);
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  const nextOptions = (tc) => {
    setNextOption(tc)
  }
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const funModal=(typeBol,redirectUrl)=>{
    if (redirectUrl!='modalPerfile') {
      props.history.push(redirectUrl)
      //console.log(props)
      return
    }
      setForModal(typeBol)
  }
  const headerCard = (data)=>{
                return <><Row>
                  <Col md={12}>
                    <h5 className="h3 mb-0" 
                    //style={{color:'white'}}
                    >{data.title}</h5>
                  </Col>
                </Row></>
  }
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  const contentCard = () => {
    //(type_prod?.indexOf('G')!=-1)?():(null)
  if (type_prod=="ON-RE-VA" || type_prod==undefined) {
    var data=[]
  }else{    
    var data = [
    //{icon:'pi pi-cog', title:'Gestión de cultivo',pathname:'/admin/list-trace-production',description:'En esta Plataforma podrá documentar todo el ciclo de su Gestión de cultivo, para ser mas eficiente y productivo.'},
    //{icon:'pi pi-map', title:'Trazabilidad',pathname:'/admin/process-trace',description:'En esta Plataforma podrá realizar la trazabilidad de sus productos.'},
    //{icon:'pi pi-shopping-bag', title:'Comercialización',pathname:null,description:'En esta Plataforma podrá comercializar sus productos directo a compradores reales y evitar los intermediarios que no aportan valor.'},
      {icon:'pi pi-user', title:'Administrador de Usuarios',pathname:'modalPerfile',description_title:'Seleccione el administrador de usuarios',description:'Aqui podrá cambiar el area de trabajo que desee gestionar.'},
      {icon:'pi pi-file-o', title:'Capacitación',pathname:'/admin/documentation'+window.location.search,description_title:'Presione para aprender a usar la plataforma',description:'Presione para aprender a usar la plataforma.'},
      {icon:'pi pi-users', title:'Agregar Colaborador',pathname:'/admin/add-collaborator'+window.location.search,description_title:'Invitar a colaborar',description:'Aqui podrá agregar a los usuarios que realicen las actividades dentro de su empresa.'},
      {icon:'pi pi-credit-card', title:'Administración',pathname:'/admin/services-pay'+window.location.search,description_title:'Presiona para entrar al panel administrativo',description:'Desde aqui podrá realizar las actividades administrativas, pagos por los servicios y compras realizadas.'},
    //{icon:'pi pi-star', title:'Capacitación',pathname:'/admin/user-manual',description:'Desde aqui podrá obtener Capacitación sobre el uso de las plataformas de Foodchain.'},
    ]
  }
var getTypeAreaWork = localStorage.getItem('type_area_work')
var dataFilter = data
if (jsondata.userType==2) {
  dataFilter = data.filter((a,b)=> a.title!='Trazabilidad' && a.title!='Capacitación' && a.title!='Usuarios' && a.title!='Administración' && a.title!='Gestión de cultivo' && a.title!='Agregar Colaborador')
}else{
  if (getTypeAreaWork=='trazability') {
    dataFilter = data.filter((a,b)=>a.title!='Gestión de cultivo' && a.title!='Comercialización')
  }
  if (getTypeAreaWork=='production') {
    dataFilter = data.filter((a,b)=>a.title!='Trazabilidad' && a.title!='Comercialización')
  }
  if (getTypeAreaWork=='commerce') {
    dataFilter = data.filter((a,b)=>a.title!='Gestión de cultivo' && a.title!='Trazabilidad')
  }
}
if (type_prod=='ON' || type_prod=='RE' || type_prod=='VA') {
  props.history.push('report-production-users')
}
if (type_prod!=undefined) {
  if (type_prod?.indexOf('T')==-1) {
    dataFilter = dataFilter.filter((a,b)=> a.title!='Trazabilidad')
  }
  if (type_prod?.indexOf('P')==-1) {
    dataFilter = dataFilter.filter((a,b)=> a.title!='Gestión de cultivo')
  }
  if (type_prod?.indexOf('C')==-1) {
    dataFilter = dataFilter.filter((a,b)=> a.title!='Comercialización')
  }
}
//to={{ pathname:(val.pathname=='modalPerfile')?('#'):(val.pathname) }}
    var content = []
    dataFilter.map((val,indx)=>{
      content[indx]=<>
            <div className="col-12 col-lg-6 col-xl-3" style={{padding: '0.8rem'}}>
                <div className="card mb-0" style={{height: '174px'}}>
                    <div className="flex justify-content-between mb-1">
                        <div>
                            <span className="block" style={{fontSize: '15px', fontWeight: 'bold',textAlign: 'left', color: "#002A5C"}}>{val.title}</span>
                        </div>
                        <div className="flex align-items-center justify-content-center" style={{ width: '2.5rem', height: '2.5rem' }}>
                            {/*<i className="pi pi-shopping-cart text-blue-500 text-xl" />*/}
                            <Button 
                            tooltip={val.description} 
                            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                            //tooltipOptions={{ position: 'left' }}
                            style={{padding: '5px',backgroundColor: '#7ac142',border: '#7ac142'}}
                            //className='button-color-fod-blue'
                            onClick={()=>{funModal( (val.pathname=='modalPerfile')?(true):(false),val.pathname )}}
                            icon={val.icon}
                            size='small' />
                        </div>
                    </div>
                    <span className="text-500" style={{textAlign: 'left',fontWeight: '600'}}>{val.description_title}</span>
                </div>
            </div>
          </>
    })

    return content
  }
  return (
    <>
      <HomeCardsHeader name="Home" parentName="Bienvenido" forModalProp={forModal} closeModal={funModal} {...props} />
      {/*
className="mt-2" fluid
    */}
      <div>
        <Row style={{textAlign:'justify'}}>
          {contentCard()}
        </Row>
        {(data_tp.role==1)?(
            <Row>
              <div className="col-md-6" style={{padding: '10px'}}>
                {(jsondata?.type_traza.indexOf("P") !=-1 )?(
                    <div className="col-12 col-xl-12" style={{padding:0}}>
                        <Tooltip target=".tooltip-mess" mouseTrack />
                        <div>
                        {/*Gestión de*/}
                            <Panel

                              //tooltip="Hola"
                              //tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                              style={{fontSize: '15px'}} 
                              header={<div className="tooltip-mess" data-pr-tooltip="Aquí puede ver la información que lleva en proceso, click en la lupa de un registro de cultivo para continuar el trabajo.">Cultivos en proceso</div>}
                              toggleable 
                              collapsed={false}>
                              <ProductionTable {...props}/>
                            </Panel>
                        </div>
                    </div>
                  ):(null)}
                  
                  {(jsondata?.type_traza.indexOf("T") !=-1 )?(
                    <div className="col-12 col-xl-12" style={{padding:0}}>
                        <div>
                            <Panel style={{fontSize: '15px'}} header="Trazabilidad" toggleable collapsed={false}>
                              <TraceTable {...props}/>
                            </Panel>
                        </div>
                    </div>
                  ):(null)}
                  {(jsondata?.type_traza.indexOf(["V1F","V2F","V3F","V4F"]) !=-1 )?(
                    <div className="col-12 col-xl-12" style={{padding:0}}>
                        <div>
                            <Panel style={{fontSize: '15px'}} header="Trazabilidad" toggleable collapsed={false}>
                              <TraceTable {...props}/>
                            </Panel>
                        </div>
                    </div>
                  ):(null)}
                </div>

              <div className="col-md-6" style={{padding: '10px'}}>
                    <div className="col-12 col-xl-12" style={{padding:0}}>
                        <div>
                            <Panel style={{fontSize: '15px'}} header="Notificación" toggleable collapsed={false}>
                              <AlertMRV {...props} name="Home" content_area="0"/>
                            </Panel>
                        </div>
                    </div>
                </div>
            </Row>
          ):(null)}

        {(data_tp.role==5 || data_tp.role==2)?(
            <Row>

              <div className="col-md-12" style={{padding: '10px'}}>
                  {(["V1F","V2F","V3F","V4F"].indexOf(jsondata?.type_traza) !=-1 )?(
                    <div className="col-12 col-xl-12" style={{padding:0}}>
                        <div>
                            <Panel style={{fontSize: '15px'}} header="Trazabilidad" toggleable collapsed={false}>
                              <TraceTableKdes {...props} type_traza={jsondata?.type_traza}/>
                            </Panel>
                        </div>
                    </div>
                  ):(null)}
                </div>

            </Row>
          ):(null)}
      </div>
      {/*<CardsHeader name="Home" parentName="Bienvenido" />*/}

    </>
  );
}

export default Dashboard;
