import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

import { Button } from 'primereact/button';

import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production_nama_g,
 data_trazabilidad,
 main_content,
 data_fertilizer_production,
 fertilizer_production,
 delete_prodction_nama_g,
 delete_file_prod } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import {
 getContent,
 PutFormContentProKdes,
 PutFormContentPro,
 PutFormContentTableProd,
 forNumeric,
 PutFormContentFinal
} from 'views/pages/forms/LogisticsForm';
import {
 PutFormContentProDuplicate
} from 'views/pages/forms/LogisticsFormDuplicate';
import { Accordion, AccordionTab } from 'primereact/accordion';

import {
 setFieldValueDuplicateHook,
 setFieldValueSelectDuplicateHook,
 geolocacionDuplicateHook
} from './HookLogistics';
import { InputText } from 'primereact/inputtext';

import {GuideMessage} from "views/pages/components/GuideMessage";
import jQuery from 'jquery';
export default class GNamaPLPreparation extends React.Component {
  static contextType = UserContext
  state={
    field_params:{},
    field_params_duplicate:[],
    field_params_view:[],
    field_content:[],
    field_content_duplicate:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],    
    dataStatesS:0,
    content_render:null,
    get_content:[],
    get_first_info:'',
    btnd:false,
    change_mrv_data:0,
    userTypeH:1,
    for_filters:{},
    verify_if_duplicate:false,
    count_insert:0
  }
  async componentDidMount(){
    this.putContentFilter(this.props.dataMainContent)
  }

  initData = async (set_data,verify,typem)=>{
     var get_valid = Object.values(this.props.dataMainContent).some((element)=>element.name_field=='field_duplicate')
     this.setState({verify_if_duplicate:get_valid})
    if (set_data==null) {
      this.saveForBack()
    }
    this.props.modalInfoProp(set_data,verify,typem)
    var data_select = []
      if (this.props.dataMainContent!=null) {
        this.setState({field_content: Object.values(this.props.dataMainContent) })
      }
      this.setState({get_content: Object.values(this.props.dataMainContent)})
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var dataTrazabilidadvf = set_data
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
            this.cleanData(Object.values(this.props.dataMainContent),verify)
        }else{
            this.setDataCampos(dataTrazabilidadvf,Object.values(this.props.dataMainContent),verify)
        }
  }
putContentFilter=(propsFilter)=>{
var get_select_data_filter = {}
   var get_data = Object.values(propsFilter).filter(a=> a.link_field!=null && a.link_field!='')
   get_data.map((val,ind)=>{
    var get_data_filter = val.link_field.split('|')
    var get_option_data = val.value_option.split('|')
    get_data_filter.map((valTwo,indxTwo)=>{
      valTwo.split('*').map((valThree,indxThree)=>{
        get_select_data_filter[valThree]= {option:get_option_data[indxTwo],field:val.name_field,valid:false}
      })
    })
   })
   this.setState({for_filters:get_select_data_filter})
}
   componentDidUpdate(prevProps, prevState, snapshot) {
    var return_result = forNumeric(prevState,this.state)
    if (return_result!=null) {
      this.setState({field_params: {...this.state.field_params, [return_result.field_product]: parseInt(return_result.product_result) }})
    }
    //console.log(this.props.set_data_modal)
      if (prevProps.set_data_modal != this.props.set_data_modal) {
        if (this.props.set_data_modal!=undefined) {
          this.initData(this.props.set_data_modal,this.props.sendOpenModal,'data')
        }
      }
        if (Object.values(this.state.field_params).length==0) {
          this.initData(this.props.set_data_modal,this.props.sendOpenModal,'data')
        }

    }

     setDataCampos = (data,dataMainContent,pass_select)=>{
           var date = new Date()
           var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
           var completeDateBefore = completeDateAfter.split('/')
           var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
           var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
       //delete data.id
       delete data.count_table
       //this.setState({field_params:data})
       var field_params_title={}
       var field_params_required={}
       var field_params_select={}
       var field_params_params={}
       var field_params_detail_mrv={}
       var new_content=[]
       var get_view_data = ''
       dataMainContent.map((a,b)=>{
         field_params_title[a.name_field]=a.label_title
         field_params_required[a.name_field]=a.req_field

         if (data[a.name_field]==null) {
          field_params_params[a.name_field]=''
          field_params_detail_mrv[a.name_field]=''
         }else{
          field_params_params[a.name_field]=data[a.name_field]
          field_params_detail_mrv[a.name_field]=""
            if (a.type_field=='date') {
              //field_params_params[a.name_field]=data[a.name_field]
              field_params_detail_mrv[a.name_field]=data[a.name_field]
            }
            if (a.type_field=='time') {
              //field_params_params[a.name_field]=data[a.name_field]
              field_params_detail_mrv[a.name_field]=data[a.name_field]
            }
          if (a.type_field=='file') {
            if (typeof data[a.name_field]=='string') {
             if (data[a.name_field].split('|').length>0) {
              var multi_fil = []
               data[a.name_field].split('|').map((a,b)=>{
                 multi_fil[b] = {name:a,filed:null}
               })
                var item = []
                item = [...multi_fil]
              field_params_params[a.name_field]=item
             }
            }
          }
          if (a.include_info==1) {
            field_params_detail_mrv[a.name_field]=data[a.name_field]
          }
         }
          if (a.type_field=='date') {
            if (data[a.name_field]==null) {
              field_params_params[a.name_field]=completeDate
            }
            //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
          }
          if (a.type_field=='time') {
            if (data[a.name_field]==null) {
              field_params_params[a.name_field]=timeString
            }
              //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
          }
          field_params_params['created_at']=data['created_at']
         if (a.type_field=='select') {
            //this.for_filter_select_show(data[a.name_field],a.name_field)
            var get_data_select = this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            //field_params_select[a.name_field] = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')
            field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }
           if (a.include_info==1) {
             //this.setState({get_first_info:a.name_field})
             get_view_data={data:data[a.name_field],table:a.name_field}
           }
           if (a.name_field=='field_duplicate') {
            this.setFieldDuplicate(data.field_duplicate)
           }
       })
       if (pass_select) {
         this.setState({optionsSelect: field_params_select})
         this.setState({selectForFilter: field_params_select})
       }else{
         this.setState({optionsSelect: []})
         this.setState({selectForFilter: []})
       }
       var thisState= this
       var idRegister = data?.id
      jQuery(document).click(function(e) {
          if (jQuery(e.target).hasClass('view_detail_panel')) {
            //thisState.setState({idT:'null'})
            idRegister='null'
            thisState.setState({idTP:data?.id})
            thisState.setState({change_mrv_data:1})
            thisState.setState({field_params: field_params_detail_mrv,field_params_view:field_params_params })
            dataMainContent.map((a,b)=>{if (a.include_info==1) {a.dis_field=1}})
            var listItem = jQuery(e.target);
            var getIndex = jQuery( ".view_detail_panel" ).index( listItem )
            thisState.setState({putPositionBtn:getIndex})
          }else{
            thisState.setState({change_mrv_data:0})
            if (jQuery(e.target).hasClass('newRegister')) {
              //thisState.setState({idT:'null'})
              idRegister='null'
              thisState.setState({field_params_view:[] })
            }else{
              if (!jQuery(e.target).hasClass('upload_files')) {
                  //thisState.setState({idT:data?.id})
                  idRegister=data?.id
                if (Object.values(thisState.state.field_params).length==0) {
                  thisState.setState({field_params: field_params_params,field_params_view:[] })
                }

              }
            }
             dataMainContent.map((a,b)=>{if (a.include_info==1) {a.dis_field=0}})
          }
      });
      this.setState({idT:idRegister})
      //console.log(idRegister)
         this.for_filter_select_show(get_view_data.data,get_view_data.table,field_params_params)
         this.setState({field_params_title: field_params_title})
         this.setState({field_params_required: field_params_required})
     }
     cleanData = (dataMainContent,pass_select) =>{
           this.setState({idT:'null'})
           var date = new Date()
           var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
           var completeDateBefore = completeDateAfter.split('/')
           var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
           var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
           var field_params_title={}
           var field_params_required={}
           var field_params_select={}
           var field_params_params={}
       dataMainContent.map((a,b)=>{
         field_params_params[a.name_field]=''
         field_params_title[a.name_field]=a.label_title
         field_params_required[a.name_field]=a.req_field
         if (pass_select) {
           if (a.type_field=='date') {
            field_params_params[a.name_field]=completeDate
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
           }
           if (a.type_field=='time') {
            field_params_params[a.name_field]=timeString
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
           }
         }else{          
           if (a.type_field=='date') {
            field_params_params[a.name_field]=null
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
           }
           if (a.type_field=='time') {
            field_params_params[a.name_field]=null
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
           }
         }
          // if (a.type_field=='file') {
          //     var item = [{name:'',filed:null}]
          //     field_params_params[a.name_field]=item
          // }
          field_params_params['created_at']=completeDate+' '+timeString
         if (a.type_field=='select') {
            var get_data_select =this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')

            field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }
       })
       if (pass_select) {
         this.setState({optionsSelect: field_params_select})
         this.setState({selectForFilter: field_params_select})
       }else{
         this.setState({optionsSelect: []})
         this.setState({selectForFilter: []})
       }
         this.setState({field_params: field_params_params })
         this.setState({field_params_title: field_params_title })
         this.setState({field_params_required: field_params_required })
     }
   saveForBack=()=>{
    this.props.setForBackModal(this.state.field_params,this.state.idT)
    this.setState({
      field_content_duplicate:[]
    })
   }
   setFieldDuplicate=(data)=>{
    if (data!='' && data!=null) {
      var field_params_duplicate = JSON.parse(data)

      var getDataTwo = []
      var getDataTwoValue = []

      var getData = {}
      var getDataValue = {}

      var get_content_duplicate = Object.values(this.props.dataMainContent).filter(a=>a.duplicate_field==1)
        for (var i = 0; i < parseInt(field_params_duplicate.length); i++) {
          get_content_duplicate.map((a,b)=>{
            getData[b]={content:a,table:a.name_field}
          })
              getDataTwo[i]=getData
              getDataTwoValue[i]=field_params_duplicate[i]
        }
      this.setState({
        field_content_duplicate:getDataTwo,
        field_params_duplicate:getDataTwoValue
      })
    }
   }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  saveInfo = async () =>{

      this.setState({btnd:true})
      this.setState({mess:['Enviando...']})
      if (this.state.field_params_required==undefined) {
        this.setState({btnd:false})
        return
      }
    
    var field_data = Object.entries(this.state.field_params)
     var field_data_remove=[]
     var counter= 0
     field_data.map((c,i)=>{
       if (c[0]!='created_at' && c[0]!='id') {
         field_data_remove[counter]=c
         counter++
       }
     })
    var field_data_name = Object.entries(this.state.field_params_title)
    var data_mess = []
    var data_for_file = []
    var counter = -1
    Object.entries(this.state.field_params_required).map((a,b)=>{
        if (field_data[b]!=undefined) {
          if (field_data[b][0].indexOf('file_')!=-1) {
            counter++
           data_for_file[counter] = {[field_data[b][0]]:field_data[b][1]}
          }
        }
        if (field_data[b]!=undefined) {
          if (field_data[b][0]==a[0]) {
           if (a[1]==1 && (field_data[b][1]=='' || field_data[b][1]==null)) {
            counter++
            var get_t = field_data_name.filter(af=>af[0]==a[0])
            if (get_t[0]!=null) {
              data_mess[counter] = 'El campo '+get_t[0][1]+' es requerido';
            }else{
              data_mess[counter] = 'El campo '+a[0]+' es requerido';
            }
           }
          }
        }
    })
    if (data_mess.length>0) {
      this.context.setToastMess('error', 'Validación', data_mess.filter(Boolean)[0])
      //this.setState({mess: data_mess.filter(Boolean) })
      this.setState({btnd:false})
      return
    }else{
      this.setState({btnd:false})
      this.setState({mess:''})
    }

       var gerParam = new URLSearchParams(window.location.search).get("code");
       var userdata = localStorage.getItem('user');
       var jsondata = JSON.parse(userdata);
         this.setState({btnd:true})
         this.saveForBack()

          const var_vf = await create_production_nama_g(jsondata._id,field_data_remove.sort(),field_data_name.sort(),gerParam,this.props.tableCall,this.state.idT,data_for_file,this.props.titleCall);
          if (var_vf==null) {
            this.setState({btnd:false})
            return
          }
              if (var_vf.message=="exito") {
                this.context.setToastMess('success', 'Guardado', 'Información actualizado')
                this.setState({mess:''})
                this.setState({btnd:false})
                await this.props.refreshDataModal()
                this.props.hide()
                if (this.state.idT=='null') {
                 this.setState({idT:var_vf.register_id})
                 this.initData(null,false,'modal')
                }else{
                 var get_data_new = this.props.trazaData.filter((a,b)=>a.id==this.state.idT)
                this.setState({optionsSelect: []})
                this.setState({selectForFilter: []})
                 this.initData(get_data_new[0],true,'data')
                }
               if (this.state.change_mrv_data==1) {
                //jQuery('#btn_change_state_'+this.state.idTP).click()
                jQuery(jQuery('.btn_change_state')[this.state.putPositionBtn]).click()
               }
              }
  }

 geolocacion = (options,indx_position) => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerPosicion(e,options,indx_position)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    VerPosicion = (posicion,optcon,indx_position)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    this.setFieldValue(lat+','+lon+'-(Información restante)',optcon.id,optcon)
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              this.setinfolocation(lat,lon,data,optcon,indx_position)
            }

        }).catch(err => console.log(err.message)));
    }
         setinfolocation = (lat,lon,data,optcon) => {
              this.setState({loadlocali:null})
              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }

    noPermitir = (argument) => {
      this.setState({loadlocali:null})
    }
     contentForSelect = (data,field_name,isrequerid,list_default)=>{
      
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.for_filter_select_show(value,field,this.state.field_params)
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
    for_filter_select_show = (value,field,dataParams)=>{
      var getData={}
      var get_content=Object.values(this.props.dataMainContent)
      var getDataValid = get_content.filter(val=>val.name_field==field)
      if (getDataValid[0].link_field==null || getDataValid[0].link_field=='') {
        return
      }

      var getDataParam = dataParams
      Object.entries(this.state.for_filters).map((a,b)=>{
        if (a[1].option==value) {
          a[1].valid=true
        }else{
          if (a[1].field==field) {
            a[1].valid=false
            //console.log(getDataParam[a[0]],a[0])
            getDataParam[a[0]]=null
          }
        }
        getData[a[0]] = a[1]
      })
      //console.log(getDataParam,getData)
      this.setState({field_params: getDataParam })
      this.setState({for_filters:getData})
    }
     setFieldValue = (value, field, isrequired) =>{
       this.setState({field_params: {...this.state.field_params, [field]:value }})
       if (isrequired!=null) {      
         this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
          if (isrequired.getAttribute('data_filter')!=null) {
            isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
              if (a!='upf') {
                Object.entries(this.state.selectForFilter).map((val,indx)=>{
                  if (val[0]==a) {
                    var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                    this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
                  }
                })
              }
            })
          }
       }

    }

     setFieldValueFiles = (value, field, isrequired) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        //console.log(value[b])
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       var items = item[field]
       items = [...items,...multi_fil]
       item[field]=items
       this.setState({field_params: item })
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
       if (isrequired.getAttribute('data_filter')!=null) {
         isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
           if (a!='upf') {
             //this.setState({field_params: {...this.state.field_params, [a]:value }})
             Object.entries(this.state.selectForFilter).map((val,indx)=>{
               if (val[0]==a) {
                 var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                 this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
               }
             })
           }
         })
       }
    }
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          var data_s = await delete_file_prod(this.state.idT,this.props.tableCall,field,data_clean.join('|'),url_selected)
          if (data_s.message=='exito') {
             var get_data_new = this.props.trazaData.filter((a,b)=>a.id==this.state.idT)
          }
         }
      this.setState({field_params: item })
       }
    }

     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          var dataSelect = {}
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        dataSelect = this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default)
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
          return dataSelect
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      var dataSelect = {}
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default=='ldp') {
          var var_data = []
          dataSelect = this.contentForSelect(value_option,name_field,req_field,list_default)
          var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
        }
        if (list_default=='cafv') {
          dataSelect = this.contentForSelect(CodigoAranF,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
        }
        if (list_default=='cac') {
          dataSelect = this.contentForSelect(CodigoAranM,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
        }
        if (list_default==null || list_default=='0') {
          dataSelect = this.contentForSelect(value_option,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(value_option,name_field,req_field,list_default) }})
        }
      }
      //this.setState({selectForFilter: this.state.optionsSelect})
      return dataSelect
          //console.log(list_default,value_option,name_field,req_field,for_values,this.state.optionsSelect)
    }
 forSearhData = (query,data) => {

    return new Promise((resolve, reject) => {
            if (data.length!=0) {
                resolve(data.filter((a,b) => a.value.toUpperCase().indexOf(query.toUpperCase())!=-1))
            }
    });

}
   setFieldValueDuplicate = (position, value, field, isrequired) =>{
    setFieldValueDuplicateHook(position, value, field, isrequired, this)
  }
  setFieldValueSelectDuplicate=(position, value, field, isrequired,code_country,list_default,type_cn,if_states)=>{
    setFieldValueSelectDuplicateHook(position, value, field, isrequired,code_country,list_default,type_cn,if_states,this)
  }
  geolocacionDuplicate=(position, options, indx_position)=>{
    geolocacionDuplicateHook(position, options, indx_position, this)
  }
  addRowsNews=(setCounter)=>{
    if (setCounter=='') {
        this.setState({count_insert:0})
    }else{
      this.setState({count_insert:setCounter})
    }
  }
  setFieldData=(setCounter)=>{
    var getData = {}
    var getDataValue = {}

    var getDataTwo = []
    var getDataTwoValue = []
    var getLenData = this.state.field_content_duplicate
    var getDataParams = this.state.field_params_duplicate
    var get_content_duplicate = this.state.field_content.filter(a=>a.duplicate_field==1)
    var counter = 1
      for (var i = 0; i < parseInt(setCounter-1); i++) {
        get_content_duplicate.map((a,b)=>{
          getData[b]={content:a,table:a.name_field}
          getDataValue[i]={[a.name_field]:'',['id']:getDataParams.length+counter+1}
          //getDataValue[i]['id']=counter
        })
          counter++
            getDataTwo[i]=getData
            getDataTwoValue[i]=getDataValue[i]
      }
      getLenData.push(...getDataTwo)
      getDataParams.push(...getDataTwoValue)

    this.setState({
      field_content_duplicate:getLenData,
      field_params_duplicate:getDataParams
    })
    this.setState({field_params: {...this.state.field_params, ['field_duplicate']:JSON.stringify(getDataParams) }})
  }
  deleteFieldDuplicated=(idDelete)=>{
    var getLenData = this.state.field_content_duplicate
    var getDataParams = this.state.field_params_duplicate
    getLenData.splice(idDelete, 1);
    getDataParams.splice(idDelete, 1);
    this.setState({
      field_content_duplicate:getLenData,
      field_params_duplicate:getDataParams
    })
    this.setState({field_params: {...this.state.field_params, ['field_duplicate']:JSON.stringify(getDataParams) }})
  }
  headerAccord = (indexing,idD)=>{
return  (<>
        <Row>
          <Col md={10} style={{alignContent: 'center'}}>
          Producto aplicado {idD}
          </Col>
          <Col md={1}>
            <button className="btn" style={{height: '100%'}} onClick={()=>{this.deleteFieldDuplicated(indexing)}}>
              <span className="fas fa-trash-alt"></span>
            </button>
          </Col>
        </Row>
          </>)
  }
  setContentDuplicate=(rowContent)=>{
    var putContent = []
    rowContent.map((a,b)=>{
      var get_content=Object.values(a).map((ac,bc)=>{ return ac.content })
      putContent[b]=<>
      <Accordion multiple activeIndex={Object.keys(rowContent).map(Number)}>
          <AccordionTab key={b} header={this.headerAccord(b,this.state.field_params_duplicate[b]?.id)}>
            <Row>
              <PutFormContentProDuplicate
              content={get_content}
              field_params={this.state.field_params_duplicate[b]}
              optionsSelect={this.state.optionsSelect}
              setFieldValue={this.setFieldValueDuplicate.bind(this,b)}
              setFieldValueSelect={this.setFieldValueSelectDuplicate.bind(this,b)}
              geolocacion={this.geolocacionDuplicate.bind(this,b)}
              forSearhData={this.forSearhData}
              for_filter_options={this.state.for_filters}
              //setFieldValueFiles={this.setFieldValueFiles}
              //deleteImgState={this.deleteImgState}
               />
            </Row>
          </AccordionTab>
        </Accordion>
{/*        <Row>
          <Col md={2} style={{display: 'flex'}}>
                    <p style={{margin:'auto'}}>
                      Producto aplicado {b+1}
                    </p>
          </Col>
          <Col md={9}>
            <Row>
              <PutFormContentProDuplicate
              content={get_content}
              field_params={this.state.field_params_duplicate[b]}
              optionsSelect={this.state.optionsSelect}
              setFieldValue={this.setFieldValueDuplicate.bind(this,b)}
              setFieldValueSelect={this.setFieldValueSelectDuplicate.bind(this,b)}
              geolocacion={this.geolocacionDuplicate.bind(this,b)}
              forSearhData={this.forSearhData}
              for_filter_options={this.state.for_filters}
               />
            </Row>
          </Col>
          <Col md={1}>
            <button className="btn" style={{height: '100%'}} onClick={()=>{this.deleteFieldDuplicated(b)}}>
              <span className="fas fa-trash-alt"></span>
            </button>
          </Col>
        </Row>*/}
      <hr style={{border:'solid 1px black'}} />
      <br />
      </>
    })
      return putContent
  }

   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
 insertNewRegister =()=>{
  var date = new Date()
  var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
  var completeDateBefore = completeDateAfter.split('/')
  var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
  var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
  //if (this.state.field_params[this.state.field_params.length-1]!=undefined) {
  // var push_shift = [...this.state.field_params,{id:this.state.field_params[this.state.field_params.length-1].id+1}]
  // }else{
  // }
  var push_shift = [...this.state.field_params,{id:null}]
  this.setState({field_params: push_shift })
 }

     listCountryCode = CountrysData.sort(this.listaArray)
  render(){
  return (
    <>
       <CardHeader style={{padding: 0}}>
            <Row>
              {(this.state.verify_if_duplicate)?(<>
                  <Col md={4}>
                            <InputText
                              onChange={(e) => {this.addRowsNews(e.target.value)}}
                              placeholder="Cantidad de productos"
                              type="number"
                            />
                  </Col>
                  <Col md={4}>
                            {(this.state.count_insert!=0)?(<button className="btn" onClick={()=>{this.setFieldData(this.state.count_insert)}}>Agregar productos</button>):(null)}
                  </Col>
                </>):(null)}
{/*              <Col md={(this.state.verify_if_duplicate)?(5):(1)} style={{textAlign: 'end'}}>
                <a href="#" onClick={()=> {this.initData(null,false,'modal')} }>
                  <h3 class="mb-0">X</h3>
                </a>
              </Col>*/}
            </Row>
          </CardHeader>
          <CardBody>
          {(this.state.change_mrv_data==1)?(<>
              <h3 className="mb-0">
                Información anterior
              </h3>
            <Row>
              <PutFormContentFinal 
              content={this.state.field_content}
              field_params={this.state.field_params_view}
              optionsSelect={this.state.optionsSelect}
              setFieldValue={this.setFieldValue}
              setFieldValueSelect={this.setFieldValueSelect}
              geolocacion={this.geolocacion}
               />
            </Row>
            <hr />
              <h3 className="mb-0">
                Nueva información
              </h3>
            </>
            ):(null)}
          <Row>
          <PutFormContentProKdes 
          content={this.state.field_content}
          field_params={this.state.field_params}
          optionsSelect={this.state.optionsSelect}
          setFieldValue={this.setFieldValue}
          setFieldValueFiles={this.setFieldValueFiles}
          setFieldValueSelect={this.setFieldValueSelect}
          deleteImgState={this.deleteImgState}
          forSearhData={this.forSearhData}
          geolocacion={this.geolocacion}
          for_filter_options={this.state.for_filters}
           />
          </Row>
            {(this.state.verify_if_duplicate)?(<>
              <hr style={{margin: 0}} />

                {this.setContentDuplicate(this.state.field_content_duplicate)}

              </>):(null)}

          <div className="percent_load"></div>
                  {this.state.mess?.length>0 ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess[0]}
                        </span>
                      </small>
                    </div>
                  ) : null}
          </CardBody>
            <div className="absolute z-2 bottom-0 right-0 w-full surface-200 border-top-1 border-400">
                <div className="flex justify-content-end pt-3 pr-3 pl-3">
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button 
                                label="Cerrar"
                                 severity="secondary" 
                                 onClick={() => this.props.hide()} size="small" />
                        </div>

                        <div className="field col">
                            <Button 
                            disabled={this.state.btnd}
                            onClick={()=>{
                              this.saveInfo()
                            }} label="Guardar" size="small" className='btn-fod-green' />
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
 }
}

