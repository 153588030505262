'use client'
import React, { useState } from 'react';
import CardProduction from './CardProduction';
import CardInfo from './CardInfo';
import CardPhytosanitary from './CardPhytosanitary';
import CardHarvest from './CardHarvest';
import CardDocuments from './CardDocuments';
import CardEndProduction from './CardProductionEnd';

const Panel = (props) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  return (
    <div className='card-table'>
      <div className="formgrid grid justify-content-center">
        <div className="field col-3">
          <CardInfo />
        </div>

        <div className="field col-3">
          <CardProduction {...props} />
        </div>

        <div className="field col-3">
          <CardPhytosanitary {...props} />
        </div>
      </div>

      <div className="formgrid grid justify-content-center">
        <div className="field col-3">
          <CardHarvest {...props} />
        </div>
        {(jsondata.userType==1)?(<>
            <div className="field col-3">
              <CardDocuments {...props} />
            </div>

            <div className="field col-3">
              <CardEndProduction {...props} />
            </div>
          </>):(null)}

      </div>
    </div>
  )
}

export default Panel