import { Link } from "react-router-dom";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import logo from "../../assets/img/brand/presentation.jpeg";

function IndexHeader() {
  return (
    <>
      <div className="header bg-traza-blue pt-7 pb-7">
        <Container>
          <div className="header-body">
            <Row className="align-items-center">
              <Col lg="7">
                <div >
{/*                  <h1 className="display-2 text-white font-weight-bold mb-0">
                    Foodchain
                  </h1>*/}
                  <h3 className="display-4 text-white font-weight-light" style={{textAlign:'justify'}}>
                    {/*Una plataforma donde puedes realizar trazabilidad de distintos productos.*/}
                    {/*Foodchain conecta el ciclo de producción de alimentos, desde el productor hasta el comprador mediante una plataforma digital eficiente confiable y transparente.*/}
                    Foodchain conecta cada etapa del ciclo de producción alimentaria a través de una plataforma digital eficiente confiable y transparente. Esta innovadora herramienta ofrece servicios de Gestión de Cultivos y Trazabilidad cumpliendo con los exigentes estándares del FDA.
                  </h3>
                  <div className="text-center">
                    <Button
                      className="btn-white my-2"
                      color="traza-green"
                      to="/admin/home"
                      tag={Link}
                    >
                      Entrar
                    </Button>
                    <Button
                      className="my-2"
                      color="traza-gray"
                      to="/home/register"
                      tag={Link}
                    >
                      ¿No tienes una cuenta?
                    </Button>
                    {/*<Button
                                          className="my-2"
                                          color="traza-gray"
                                          to="/home/vista-trace"
                                          tag={Link}
                                        >
                                          Vista Trace
                                        </Button>*/}
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <Row>
                  <Card style={{padding:'1px',borderBottomLeftRadius: '0px',borderTopRightRadius: '0px', borderTopLeftRadius: '20px',borderBottomRightRadius: '20px'}}>
                    <div style={{"textAlign": "center"}}>
                      <img width="100%" src={logo} alt="Foodchain Trace" style={{borderTopLeftRadius: '20px',borderBottomRightRadius: '20px'}} />
                    </div>
                  </Card>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-traza-grayblue" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
