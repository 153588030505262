import React, {useState, useRef} from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import NotificationAlert from "react-notification-alert";
import {register} from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import {useHistory} from "react-router-dom";
import config from "config";

const RegisterMRV = () => {
  const [focusedName, setfocusedName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedEmpresa, setfocusedEmpresa] = useState(false);
  const [focusedPais, setfocusedPais] = useState(false);
  const [focusedTrazabilidad, setfocusedTrazabilidad] = useState(false);
  const [focusedCargo, setfocusedCargo] = useState(false);
  const [focusedPhone, setfocusedPhone] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedConfirmPassword, setfocusedConfirmPassword] = useState(false);

  const [name, setName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [focusedSecondName, setfocusedSecondName] = useState(false);

  const [pais, setPais] = useState("");
  const [trazabilidad, setTrazabilidad] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [areawork, setAreawork] = useState("");
  const [email, setEmail] = useState("");
  const [cargo, setCargo] = useState("");
  const [phone, setPhone] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [focusedDni, setfocusedDni] = useState(false);
  const [dni, setDni] = useState("");

  const [checkbox, setCheckbox] = useState(false);
  const [error, setError] = useState("");
  const [toastMessage, setToastMessage] = useState("Verifique su correo.");
  const [alert, setalert] = useState(false);
  const { DEMO } = config;
  const [demo, setDemo] = useState(DEMO);
  const notificationAlertRef = useRef(null);
  const history = useHistory();

  const notify = (type, title, message, userID) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const registerUser = async () => {
    setError("Cargando...");
    if (name=='' || secondName=='' || email=='' || dni=='' || cargo=='' || phone=='' || empresa=='' || pais=='0' || (trazabilidad=='0' || trazabilidad=='')) {
      setError("Todos los campos son necesarios.");
      return;
    }
    if (password !== confirmPassword) {
      setError("las contraseñas no coinciden.");
      return;
    }
    //const response = undefined
     const response = await register(name, secondName, email, empresa, pais, trazabilidad, password, 4, phone, cargo, dni)
     .catch(function (message){
       setError(JSON.stringify(message.response.data.errors))
     })
    if (response==undefined) {
      return;
    }else{
    const {data} = response;
      if (!data.success) {
          setError(data.msg);
          return;
      }
    }
    // if (demo) {
         setToastMessage("Antes de ingresar verifique su correo.");
    // }
    setError("");
    setName("");
    setEmail("");
    setDni("");

    setPais("0");
    setEmpresa("");

    setPassword("");
    setConfirmPassword("");
    setCheckbox(false);
    notify("success", "¡Registrado exitosamente!, verifique su correo", toastMessage);
    // localStorage.setItem("token", data.datosUser.token);
    // localStorage.setItem("photo_profile", data.datosUser.namephoto);
    // localStorage.setItem("user_wallet", data.datosUser.wallet);
    // localStorage.setItem("user", JSON.stringify(data.datosUser));
    //setTimeout(function(){history.push('/home/login')}, 3000);
    setTimeout(function(){ history.push('/home/validation',{email: email}) }, 3000);
  };
  const listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
    const listCountryCode = CountrysData.sort(listaArray)
    const changeAreaWork = (val) => {
      var data = ''
      if (val.checked) {
        data = areawork+val.value
        //areawork
      }else{
        data = areawork.replace(val.value,'')
      }
      setAreawork(data)
    }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthHeader
        title="Cree su Cuenta MRV"
        lead="(Monitoreo, Revisión, Validación)"
      />
    {/*lead="Usa este formulario para crear tu cuenta y poder ingresar al sistema."*/}
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb--4">
              <CardBody className="px-lg-5 py-lg-3">
{/*                <div className="text-center text-muted mb-4">
                  Ingrese su correo electronico y contraseña
                </div>*/}
                <Form role="form">
                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Nombre"
                        type="text"
                        value={name} onChange={e => setName(e.target.value)}
                        onFocus={() => setfocusedName(true)}
                        onBlur={() => setfocusedName(false)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Apellido"
                        type="text"
                        value={secondName} onChange={e => setSecondName(e.target.value)}
                        onFocus={() => setfocusedSecondName(true)}
                        onBlur={() => setfocusedSecondName(false)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Correo electrónico"
                        type="email"
                        value={email} onChange={e => setEmail(e.target.value)}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(false)}
                      />
                    </InputGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedDni,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Cédula"
                        type="text"
                        value={dni} onChange={e => setDni(e.target.value)}
                        onFocus={() => setfocusedDni(true)}
                        onBlur={() => setfocusedDni(false)}
                        name="dni"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmpresa,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-phone" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Teléfono"
                        type="text"
                        value={phone} onChange={e => setPhone(e.target.value)}
                        onFocus={() => setfocusedPhone(true)}
                        onBlur={() => setfocusedPhone(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: focusedTrazabilidad,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-ellipsis-h" />
                            </InputGroupText>
                          </InputGroupAddon>
                      <Input 
                      type="select" 
                      value={trazabilidad} 
                      onChange={e => setTrazabilidad(e.target.value)}
                      onFocus={() => setfocusedTrazabilidad(true)}
                      onBlur={() => setfocusedTrazabilidad(false)}
                            >
                        <option value="0">Seleccione</option>
                        <option value="ON">Monitoreo</option>
                        <option value="RE">Revisión</option>
                        <option value="VA">Validación</option>
                        <option value="ON-RE-VA">MRV</option>
                      </Input>
                        </InputGroup>
                      </FormGroup>


                  <FormGroup
                    className={classnames({
                      focused: focusedEmpresa,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-shop" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Empresa/Institución"
                        type="text"
                        value={empresa} onChange={e => setEmpresa(e.target.value)}
                        onFocus={() => setfocusedEmpresa(true)}
                        onBlur={() => setfocusedEmpresa(false)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: focusedEmpresa,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Cargo"
                        type="text"
                        value={cargo} onChange={e => setCargo(e.target.value)}
                        onFocus={() => setfocusedCargo(true)}
                        onBlur={() => setfocusedCargo(false)}
                        autoComplete="none"
                      />
                    </InputGroup>
                  </FormGroup>
                  
                 <FormGroup
                    className={classnames({
                      focused: focusedPais,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-map-big" />
                        </InputGroupText>
                      </InputGroupAddon>
                  <Input 
                  type="select" 
                  value={pais} 
                  onChange={e => setPais(e.target.value)}
                  onFocus={() => setfocusedPais(true)}
                  onBlur={() => setfocusedPais(false)}
                        >
                    <option value="0">Pais</option>
                   {  listCountryCode.map((value,index)=>{
                       return <option value={value.Name}>{value.Name}</option>
                      })
                    }
                  </Input>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        value={password} onChange={e => setPassword(e.target.value)}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedConfirmPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirmar contraseña"
                        type="password"
                        value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}
                        onFocus={() => setfocusedConfirmPassword(true)}
                        onBlur={() => setfocusedConfirmPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  {error ?
                    <div className="text-muted font-italic">
                      <small>
                        Mensaje:{" "}
                        <span className="text-red font-weight-700">{error}</span>
                      </small>
                    </div> : null }
                  <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                          checked={checkbox}
                          onChange={() => setCheckbox(!checkbox)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            He leído y acepto los{" "}
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              términos y condiciones
                            </a>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button 
                      className="mt-4" 
                      color="traza-green" 
                      type="button" 
                      disabled={!email || !password || !confirmPassword || !checkbox}
                      onClick={registerUser}>
                      Crear cuenta
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RegisterMRV;
