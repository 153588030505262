import { Container, Row, Col } from "reactstrap";

function AuthFooter() {
  return (
    <>
      <footer className="py-2" id="footer-main">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="12">
              <div className="copyright text-center text-xl-left text-black" style={{fontSize:'21px'}}>
                © Foodchain 2019 - {new Date().getFullYear()}
                . Todos los derechos reservados.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default AuthFooter;