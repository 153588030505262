import React, { useContext, useEffect, useRef } from 'react';
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";

import StartVegFruits from "views/pages/forms/Trazability/StartVegFruits.js";
import FertilizerForm from "views/pages/forms/Trazability/VegetalesYFrutas/StartFertilizerForm";
import Production from "views/pages/forms/Trazability/VegetalesYFrutas/StartProductionForm";
import Cosecha from "views/pages/forms/Trazability/VegetalesYFrutas/StartCosechaForm";
import FieldTransport from "views/pages/forms/Trazability/VegetalesYFrutas/StartFieldTransportForm";
import Plant from "views/pages/forms/Trazability/VegetalesYFrutas/StartPlantForm";
import Packaging from "views/pages/forms/Trazability/VegetalesYFrutas/StartPackagingForm";
import Freezing from "views/pages/forms/Trazability/VegetalesYFrutas/StartFreezingForm";
import ComercializationFruits from "views/pages/forms/Trazability/VegetalesYFrutas/StartComercializationForm";
import FVLogistics from "views/pages/forms/Trazability/VegetalesYFrutas/StartLogisticsForm";
import MComercialization from "views/pages/forms/Trazability/VegetalesYFrutas/StartComercializationForm";
import Compartiments from "views/pages/forms/Trazability/Carnicos/StartCompartimentForm";
import Fridge from "views/pages/forms/Trazability/Carnicos/StartFridgeForm";
import MLogistics from "views/pages/forms/Trazability/Carnicos/StartLogisticsForm";
import FinalizeTraza from "views/pages/forms/Trazability/VegetalesYFrutas/FinalTraceability";

//meat
import MeatComercialization from "views/pages/forms/Trazability/Carnicos/StartComercializationForm";
import StartMeats from "views/pages/forms/Trazability/StartMeats.js";

import InitAnimal from "views/pages/forms/Trazability/Carnicos/StartRegisterAnimal.js";
import DetailsAnimal from "views/pages/forms/Trazability/Carnicos/StartDetailsAnimal.js";
import Compartiment from "views/pages/forms/Trazability/Carnicos/StartCompartimentForm.js";
import Guide from "views/pages/forms/Trazability/Carnicos/StartGuide.js";
import Slaughterhouse from "views/pages/forms/Trazability/Carnicos/StartSlaughterhouseForm.js";
import InventoryAnimal from "views/pages/tables/Trazability/Carnicos/InventoryAnimalTable.js";
import InventoryLote from "views/pages/tables/Trazability/Carnicos/InventoryLoteTable.js";
import InventoryLoteJob from "views/pages/tables/Trazability/Carnicos/InventoryLoteTableJob.js";
import InventoryAnimalDelete from "views/pages/tables/Trazability/Carnicos/InventoryAnimalDeleteTable.js";
import FinalTraceabilityMeats from "views/pages/forms/Trazability/Carnicos/FinalTraceabilityMeats.js";
import DetailsAnimalViewTrace from "views/pages/examples/Trazability/SectionsViewTraceMeats/SectionDetailAnimalView.js";
import DocumentsMeat from "views/pages/tables/Trazability/DocumentsMeats.js";
//prodction

import StartPanelGDC from "views/pages/forms/Production/Panel/panel/page.js";
import StartPanelKDES from "views/pages/forms/Trazability/FrutasYVegetalesKdes/Panel/panel/page.js";

import GNama from "views/pages/forms/Production/Grains/GrainNama/StartGrainNamaForm.js";

import GNamaInfoCultive from "views/pages/forms/Production/Grains/GrainNama/GrainNamaInfoCultiveForm.js";
import GNamaInfoPrevious from "views/pages/forms/Production/Grains/GrainNama/GrainNamaInfoPreviousForm.js";
import GNamaInfoFinance from "views/pages/forms/Production/Grains/GrainNama/GrainNamaInfoFinanceForm.js";

import GNamaPLPreparation from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLPreparationForm.js";
import GNamaPLIrrigation from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLIrrigationForm.js";
import GNamaPLSeed from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLSeedForm.js";
import GNamaPLFertilitation from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLFertilitationForm.js";
import GNamaPLsowing from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLsowingForm.js";
import GNamaPLMonitoring from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLMonitoringForm.js";
import GNamaWeather from "views/pages/forms/Production/Grains/GrainNama/GrainNamaWeather.js";
import GNamaPLSatelite from "views/pages/forms/Production/Grains/GrainNama/GrainNamaPLSateliteForm.js";

 import GNamaMAgro from "views/pages/forms/Production/Grains/GrainNama/GrainNamaMAgroForm.js";
 import GNamaMWeeds from "views/pages/forms/Production/Grains/GrainNama/GrainNamaMWeeds.js";
 import GNamaMPlague from "views/pages/forms/Production/Grains/GrainNama/GrainNamaMPlague.js";
 import GNamaMDiseases from "views/pages/forms/Production/Grains/GrainNama/GrainNamaMDiseases.js";

 import GNamaCHarvest from "views/pages/forms/Production/Grains/GrainNama/GrainNamaCHarvestForm.js";
 import GNamaCTransport from "views/pages/forms/Production/Grains/GrainNama/GrainNamaCTransportForm.js";
 import GNamaCPostharvest from "views/pages/forms/Production/Grains/GrainNama/GrainNamaCPostharvestForm.js";

 import GNamaFinal from "views/pages/forms/Production/Grains/GrainNama/GrainNamaFinal.js";

 import NamaReportGEI from "views/pages/forms/Production/Grains/ReportNama/NamaReportGei.js";

 import GNamaDocuments from "views/pages/forms/Production/Grains/GrainNama/GrainNamaDocument.js";

 import GTrazaInfoCultive from "views/pages/forms/Trazability/GrainTrazaStart.js";
 import GTrazaPLSeed from "views/pages/forms/Trazability/Grain/GTrazaPLSeed.js";
 import GTrazaPLFertilitation from "views/pages/forms/Trazability/Grain/GTrazaPLFertilitation.js";
 import GTrazaMAgro from "views/pages/forms/Trazability/Grain/GTrazaMAgro.js";
 import GTrazaCPostharvest from "views/pages/forms/Trazability/Grain/GTrazaCPostharvest.js";
 import GTrazaCTransport from "views/pages/forms/Trazability/Grain/GTrazaCTransport.js";

 import GTrazaCHarvest from "views/pages/forms/Trazability/Grain/GTrazaCHarvest.js";
 
 import GTrazaPLSatelite from "views/pages/forms/Trazability/Grain/GrainTrazaSatelite.js";

import VistaTraceTG from "views/pages/examples/Trazability/VistaTraceTrazaGView";

 import GTrazaDocuments from "views/pages/forms/Trazability/Grain/GTrazaDocuments.js";
 import GTrazaFinal from "views/pages/forms/Trazability/Grain/GTrazaFinal.js";


import VistaTrace from "views/pages/examples/Trazability/VistaTraceView";
import VistaTraceNG from "views/pages/examples/Production/VistaTraceNamaGView";
import ViewDetailMVR from "views/pages/examples/Production/ViewDetailMVR";

import ReportProductionFinal from "views/pages/forms/Production/MRV/ReportProductionFinal";

import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";

import StartHarvestKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/VegFruitsProducersKdes';

import StartPlantKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/PlantKdes/StartPlantKdes';
import StartPlantProductKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/PlantKdes/StartPlantProductKdes';
import StartPlantReceiverKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/PlantKdes/StartPlantReceiverKdes';
import StartPlantShippingKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/PlantKdes/StartPlantShippingKdes';

import StartImportReceiverKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/ImportKdes/StartImportReceiverKdes';
import StartImportTransformationKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/ImportKdes/StartImportTransformationKdes';
import StartImportFoodKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/ImportKdes/StartImportFoodKdes';
import StartImportShippingKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/ImportKdes/StartImportShippingKdes';

 import KdesDocuments from "views/pages/forms/Trazability/FrutasYVegetalesKdes/KdesDocuments.js";
 import KdesFinal from "views/pages/forms/Trazability/FrutasYVegetalesKdes/KdesFinal.js";

import StartDistributorReceivingKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/DistributorKdes/StartDistributorReceivingKdes';
import StartDistributorShippingKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/DistributorKdes/StartDistributorShippingKdes';

import StartDetailsReceivingKdes from 'views/pages/forms/Trazability/FrutasYVegetalesKdes/DetailsKdes/StartDetailsReceiveKdes';

import { close_session, logout, put_token_firebase, get_permission } from "network/ApiAxios";
import jQuery from 'jquery';
import NotificationAlert from "react-notification-alert";
//import { collection, addDoc } from "firebase/firestore";
import * as fes from "firebase/firestore";
import * as firedatabase from "firebase/database";
import { db } from "network/ConfigFirebase";
import {DataProvider} from './store';
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "network/ConfigFirebase";
import {UserContext} from "layouts/store";
import AppSidebar from './layout/AppSidebar';
import AppTopbar from './layout/AppTopbar';
import AppFooter from './layout/AppFooter';
import { useEventListener, useMountEffect, useUnmountEffect } from 'primereact/hooks';

import { classNames } from 'primereact/utils';
//import AppConfig from './AppConfig';
import { LayoutContext } from './layout/context/layoutcontext';
import { PrimeReactContext } from 'primereact/api';
import { Toast } from 'primereact/toast';
//import { ChildContainerProps, LayoutState, AppTopbarRef } from '@/types';
//import { usePathname, useSearchParams } from 'next/navigation';
//import { Link, useLocation } from "react-router-dom";

var filed = require('./session.txt')
    // const location = useLocation();
    // const mainContentRef = React.useRef(null);
  class Admin extends React.Component {
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.mainContentRef = React.createRef();
    this.location = props.location;
    this.state = {
      sidenavOpen: true,
      notificationAlertRef: React.createRef(),
      viewPermissionContent:null,
      viewPermissionContentSections:null,
    }
  }
  // const [sidenavOpen, setSidenavOpen] = React.useState(true);
  // const [permission,setPermission] = React.useState(1)
  // const location = useLocation();
  // const mainContentRef = React.useRef(null);

  // React.useEffect(async() => {

  // }, [location,permission,setPermission]);
    async componentDidMount(){
      document.getElementById('imgLoad').style.display='none'
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(window.location.search).get("company");
    var idcompany = null;
    if (jsondata?.userType==2) {
      if (gerParam!=null) {
        idcompany = gerParam
      }
    }
    var dataPermission = await get_permission(jsondata._id,idcompany)
    this.setState({viewPermissionContent: dataPermission[0].type_traza})
    this.setState({viewPermissionContentSections: dataPermission[0]})
    this.eventSidebar()
    //   var getIdUser = this.getUserId()
    //   getToken(messaging, {vapidKey: 'BJRCSTmj4FNLsgqYxsW6gkDVAOKklKkBor12ga3HQz5g8Ow75m7DrzX8cS84ge_ZSNNmbb0EJw2WQzDjr9y1ZC0'}).then( async(currentToken) => {
    //   if (currentToken) {
    //     await put_token_firebase(getIdUser,currentToken)
    //   } else {
    //     console.log('No registration token available. Request permission to generate one.');
    //     await put_token_firebase(getIdUser,'none')
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    // });
    this.initFireBase()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //this.mainContentRef.current.scrollTop = 0;
  }
  eventSidebar = ()=>{
      if (window.location.pathname=='/admin/dashboard') {
        jQuery('.navbar-vertical').attr('style','display: none;')
        jQuery('.main-content').attr('style','margin-left: 0px;')
      }else{
        jQuery('.navbar-vertical').removeAttr('style')
        jQuery('.main-content').removeAttr('style')
      }
  }
  async componentDidUpdate() {
    this.eventSidebar()
         firedatabase.get(this.getDBFire()).then((snapshot) => {
           if (snapshot.exists()) {
              var data = snapshot.val();
              var dataKey = Object.keys(data);
              var getData = Object.values(data)
              if (getData[getData.length-1].active==1) {
                var startDate = new Date(getData[getData.length-1].close_sessions_date)
                var endDate = new Date()
                var difference = endDate.getTime() - startDate.getTime();
                var resultInMinutes = Math.round(difference / 60000);
                  this.functionFBEdit(String(dataKey[dataKey.length-1]),getData[getData.length-1],1)
              }else{
                 this.functionFBCreate()
              }
           }
       });     
  }
initFireBase = () => {
  var userId = this.getUserId()
        firedatabase.onValue(this.getDBFire(), (snapshot) => {
          if (snapshot.exists()) {
             var data = snapshot.val();
             var dataKey = Object.keys(data);
             var getData = Object.values(data)
             if (getData[getData.length-1].notifications!=undefined) {
              jQuery("#counternoti").text(parseInt(jQuery('#counternoti').text())+1)
              if (getData[getData.length-1].collab=='Si') {
                this.notify("info", "¡Nueva Notificación!", 'Nueva Notificación: '+getData[getData.length-1].titulo);
              }
               // if (getData[getData.length-1].user_id!=userId) {
               // }
             }
             if (getData[getData.length-1].active==1) {
               var startDate = new Date(getData[getData.length-1].close_sessions_date)
               var endDate = new Date()
               var difference = endDate.getTime() - startDate.getTime();
               var resultInMinutes = Math.round(difference / 60000);
               if (resultInMinutes > 0) {
                 if (window.performance.navigation.type == window.performance.navigation.TYPE_RELOAD) {
                   this.functionFBEditOun(String(dataKey[dataKey.length-1]),getData[getData.length-1],1)
                    return;
                 }
                 if (getData[getData.length-1].notifications==undefined) {
                   this.functionFBEdit(String(dataKey[dataKey.length-1]),getData[getData.length-1],0)
                  }
               }else{
                  this.functionFBEditOun(String(dataKey[dataKey.length-1]),getData[getData.length-1],1)
               }
             }else{
              this.functionFBCreateOun()
                }
          }else{
              this.functionFBCreate()
          }
      });
}


getUserId = () => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var data_response = null
  if (jsondata?.userType==4) {
    data_response = {id_user:jsondata._id,database_user:'user/'+jsondata._id}
  }else{
    if (jsondata.database==undefined) {
      data_response = {id_user:jsondata._id,database_user:'user/'+jsondata._id}
    }else{
      data_response = {id_user:jsondata._id,database_user:jsondata.database+'/'+'user/'+jsondata._id}
    }
  }
    return data_response
}
getDBFire = () => {
const productsCollection = firedatabase.ref(db,this.getUserId().database_user)
return productsCollection
};
getDateString = () => {
      var date = new Date();
      var completeDate = date.toLocaleDateString("en-CA")
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1]+':'+String(date).split(":")[2].split(" ")[0];
      var globalTimeDate = completeDate+' '+timeString
      return globalTimeDate
};

 functionFBCreate = () => {
  var getThis = this;
             const newPostRef = firedatabase.push(getThis.getDBFire());
             firedatabase.set(newPostRef,{
                user_id: getThis.getUserId().id_user,
                close_sessions_date: String(getThis.getDateString()),
                active:1,
              }).catch(alert);
  };
 functionFBCreateOun = () => {
  var getThis = this;
          window.onunload = async function(event){
             const newPostRef = firedatabase.push(getThis.getDBFire());
             firedatabase.set(newPostRef,{
                user_id: getThis.getUserId().id_user,
                close_sessions_date: String(getThis.getDateString()),
                active:1,
              }).catch(alert);
          }
  };  
 functionFBEdit = async (indexKey,data,activ) => {
  const postData = {
    user_id: this.getUserId().id_user,
    close_sessions_date: (activ==1)?(this.getDateString()):(data.close_sessions_date),
    active: activ
  };
    var updates = {};
    updates[indexKey] = postData;    
    firedatabase.update(this.getDBFire(), updates)
    // if (activ==0) {
    //       const token = localStorage.getItem("token");
    //       const response = await logout(token);
    //       const { data } = response;
    //       if (data.success) {
    //         localStorage.removeItem("token");
    //         localStorage.removeItem("user");
    //         alert('Sessión vencida por tiempo de inactividad.')
    //         this.props.history.push("/home/login")
    //       }
    // }
  };
 functionFBEditOun = (indexKey,data,activ) => {
  var getThis = this;
          window.onunload = async function(event){
            const postData = {
              user_id: getThis.getUserId().id_user,
              close_sessions_date: (activ==1)?(getThis.getDateString()):(data.close_sessions_date),
              active: activ
            };
              var updates = {};
              updates[indexKey] = postData;    
              firedatabase.update(getThis.getDBFire(), updates)
          }
  };  
   notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  routes_kdes=(type_traza,type_objt,route_url,component_view)=>{
    var content = {}
    if (this.state.viewPermissionContent?.indexOf(type_traza)!=-1) {
      if (this.state.viewPermissionContentSections!=null) {        
        if (this.state.viewPermissionContentSections[type_objt]==1) {
          content[type_traza]= <><Route path={route_url} component={component_view} /></>
        }else{
        content[type_traza]= <><Route path={route_url} component={ViewPermision} /></>
        }
      }else{
        content[type_traza]= <><Route path={route_url} component={ViewPermision} /></>
      }
    }else{
      content[type_traza]= <><Route path={route_url} component={ViewPermision} /></>
    }
    return content
  }
  routes_kdes_logistict=(type_traza)=>{
    var data = {}
    if (type_traza=='VF') {
      data = {url:'/admin/create_harvest_kdes',component:StartHarvestKdes}
    }
    if (type_traza=='V1F') {
      data = {url:'/admin/create_plant_cooling_kdes',component:StartPlantKdes}
    }
    if (type_traza=='V2F') {
      data = {url:'/admin/create_producer_receiving_kdes',component:StartHarvestKdes}
    }
    if (type_traza=='V3F') {
      data = {url:'/admin/create_distributor_receiving_kdes',component:StartHarvestKdes}
    }
    if (type_traza=='V4F') {
      data = {url:'/admin/create_establishment_receiving_kdes',component:StartHarvestKdes}
    }
    return data
  }
    routes_kdes_logistict_route=(components,typeT,traza)=>{
      var component_view = ViewPermision
      if (this.state.viewPermissionContentSections!=null) {
        if (this.state.viewPermissionContent?.indexOf(typeT)!=-1) {
          if (this.state.viewPermissionContentSections[traza]==1) {
            component_view=components
          }
        }
      }
      return component_view
          // (this.state.viewPermissionContent?.indexOf(valk.type)!=-1)
          //           ?
          //           (
          //             (this.state.viewPermissionContentSections!=null) 
          //             ?
          //             (
          //               (this.state.viewPermissionContentSections[valk.traza]==1)
          //               ?
          //               (components)
          //               :
          //               (ViewPermision)
          //             )
          //             :
          //             (ViewPermision)
          //             )
          //           :
          //           (ViewPermision)
  }
render(){
  const getRoutes = (routes) => {

    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({sidenavOpen:!this.state.sidenavOpen})
    //setSidenavOpen(!this.state.sidenavOpen);
  };
  const getNavbarTheme = () => {
    return window.location.pathname?.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

const data_kdes = [
    {path:'/admin/create_harvest_kdes',component:StartHarvestKdes, type:'VF', traza:'cosecha'},

    {path:'/admin/create_plant_cooling_kdes',component:StartPlantKdes, type:'V1F', traza:'planta'},
    {path:'/admin/create_plant_product_kdes',component:StartPlantKdes, type:'V1F', traza:'planta'},
    {path:'/admin/create_plant_receiver_kdes',component:StartPlantKdes, type:'V1F', traza:'planta'},
    {path:'/admin/create_plant_shipping_kdes',component:StartPlantKdes, type:'V1F', traza:'planta'},

    {path:'/admin/create_producer_receiving_kdes',component:StartHarvestKdes, type:'V2F', traza:'produccion'},
    {path:'/admin/create_producer_transformation_kdes',component:StartHarvestKdes, type:'V2F', traza:'produccion'},
    {path:'/admin/create_producer_food_kdes',component:StartHarvestKdes, type:'V2F', traza:'produccion'},
    {path:'/admin/create_producer_shipping_kdes',component:StartHarvestKdes, type:'V2F', traza:'produccion'},

    {path:'/admin/create_distributor_receiving_kdes',component:StartHarvestKdes, type:'V3F', traza:'logistica'},
    {path:'/admin/create_distributor_shipping_kdes',component:StartHarvestKdes, type:'V3F', traza:'logistica'},

    {path:'/admin/create_establishment_receiving_kdes',component:StartHarvestKdes, type:'V4F', traza:'comercializa'}
  ]

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>    
{/*      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={this.state.sidenavOpen}
        logo={{
          innerLink: "/admin/home",
          imgSrc: require("assets/img/brand/foodchain-logo.png").default,
          imgAlt: "...",
        }}
      />*/}
      <div className="main-content" ref={this.mainContentRef}>

      
{/*        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          brandText={getBrandText(window.location.pathname)}
          historySend={this.props.history}
        />*/}
        <Switch>
          {getRoutes(routes)}
          {/*<Route path="/admin/permission" component={ViewPermision} />*/}

            <Route path="/admin/fruits-vegetables/production" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.produccion==1)?(Production):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/fruits-vegetables/fertilizer" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.produccion==1)?(FertilizerForm):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/fruits-vegetables/harvest" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.cosecha==1)?(Cosecha):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/fruits-vegetables/FieldTransport" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.cosecha==1)?(FieldTransport):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/fruits-vegetables/plant" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.planta==1)?(Plant):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/fruits-vegetables/packaging" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.planta==1)?(Packaging):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/fruits-vegetables/freezing" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.planta==1)?(Freezing):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/fruits-vegetables/logistics" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.logistica==1)?(FVLogistics):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/fruits-vegetables/Comercialization" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.comercializa==1)?(ComercializationFruits):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/vista-trace" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(VistaTrace):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/fruits-vegetables/finalice-traza" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('VF')!=-1)?(
              (this.state.viewPermissionContentSections?.trazabilidad==1)?(FinalizeTraza):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/start-trace/meats" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.trazabilidad_m==1)?(StartMeats):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/meats/inventory-animal" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.trazabilidad_m==1)?(InventoryAnimal):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/meats/eliminate-animal" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.trazabilidad_m==1)?(InventoryAnimalDelete):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/meats/job-lote" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.lote_m==1)?(InventoryLoteJob):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/meats/logistics" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.logistica_m==1)?(MLogistics):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/meats/compartiment" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.compartimento_m==1)?(Compartiment):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/meats/fridge" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.frigorifico_m==1)?(Fridge):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/meats/slaughterhouse" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.frigorifico_m==1)?(Slaughterhouse):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/meats/guide" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.guide_m==1)?(Guide):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/meats/doc-meats" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              (this.state.viewPermissionContentSections?.documentos_m==1)?(DocumentsMeat):(ViewPermision)
              ):(ViewPermision)):(ViewPermision)} />

            <Route path="/admin/meats/add-animal" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              InitAnimal
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/meats/details-animal" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              DetailsAnimal
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/meats/inventory-lote" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              InventoryLote
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/meats/end-traceability" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              FinalTraceabilityMeats
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/meats/details-animal" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              DetailsAnimalViewTrace
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/meats/comercialization" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              MComercialization
              ):(ViewPermision)):(ViewPermision)} />
            <Route path="/admin/meats/compartiments" component={(this.state.viewPermissionContent?.indexOf('T')!=-1)?((this.state.viewPermissionContent?.indexOf('M')!=-1)?(
              Compartiments
              ):(ViewPermision)):(ViewPermision)} />

            <Route path='/admin/gdc-panel' component={StartPanelGDC} />

            <Route path="/admin/info_producer_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.information_nama_g==1)?(GNama):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/info_crops_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.information_nama_g==1)?(GNamaInfoCultive):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/info_previous_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.information_nama_g==1)?(GNamaInfoPrevious):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/info_financing_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.information_nama_g==1)?(GNamaInfoFinance):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/production_land_preparation_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.production_nama_g==1)?(GNamaPLPreparation):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/production_irrigation_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.production_nama_g==1)?(GNamaPLIrrigation):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/production_seed_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.production_nama_g==1)?(GNamaPLSeed):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/production_fertilizer_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.production_nama_g==1)?(GNamaPLFertilitation):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/production_sowing_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.production_nama_g==1)?(GNamaPLsowing):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/production_producer_monitoring_g" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.production_nama_g==1)?(GNamaPLMonitoring):(ViewPermision)
              ):(ViewPermision)} />
            
            <Route path="/admin/production_info_weather" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.production_nama_g==1)?(GNamaWeather):(ViewPermision)
              ):(ViewPermision)} />

            <Route path="/admin/production_info_satelite" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.production_nama_g==1)?(GNamaPLSatelite):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/hading_fert_agro" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.hading_nama_g==1)?(GNamaMAgro):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/hading_info_weeds" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.hading_nama_g==1)?(GNamaMWeeds):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/hading_info_plague" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.hading_nama_g==1)?(GNamaMPlague):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/hading_info_diseases" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.hading_nama_g==1)?(GNamaMDiseases):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/harvest_info_harvest" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.harvest_nama_g==1)?(GNamaCHarvest):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/harvest_info_transport" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.harvest_nama_g==1)?(GNamaCTransport):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/harvest_info_pos_harvest" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.harvest_nama_g==1)?(GNamaCPostharvest):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/grains-nama-documents" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.document_nama_g==1)?(GNamaDocuments):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/grains-nama-end" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              (this.state.viewPermissionContentSections?.information_nama_g==1)?(GNamaFinal):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/vista-trace-admin-grain-nama" component={(this.state.viewPermissionContent?.indexOf('P')!=-1)?(
              VistaTraceNG
              ):(ViewPermision)} />

            <Route path="/admin/detail_monitoring" component={(this.state.viewPermissionContent?.indexOf('ON')!=-1 || this.state.viewPermissionContent?.indexOf('RE')!=-1 || this.state.viewPermissionContent?.indexOf('VA')!=-1)?(
              ViewDetailMVR
              ):(ViewPermision)} />

            <Route path="/admin/grains_nama_end_mrv" component={(this.state.viewPermissionContent?.indexOf('ON')!=-1 || this.state.viewPermissionContent?.indexOf('RE')!=-1 || this.state.viewPermissionContent?.indexOf('VA')!=-1)?(
              ReportProductionFinal
              ):(ViewPermision)} />

            <Route path="/admin/grain_info_crops_t" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.information_grain_t==1)?(GTrazaInfoCultive):(ViewPermision)
              ):(ViewPermision)} />

            <Route path="/admin/grain_seed_t" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.production_grain_t==1)?(GTrazaPLSeed):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/grain_fertilizer_t" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.production_grain_t==1)?(GTrazaPLFertilitation):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/grain_fert_agro_t" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.production_grain_t==1)?(GTrazaMAgro):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/grain_info_satelite_t" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.production_grain_t==1)?(GTrazaPLSatelite):(ViewPermision)
              ):(ViewPermision)} />

            <Route path="/admin/grain_harvest_info_harvest_t" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.harvest_grain_t==1)?(GTrazaCHarvest):(ViewPermision)
              ):(ViewPermision)} />

            <Route path="/admin/grain_harvest_info_transport_t" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.harvest_grain_t==1)?(GTrazaCTransport):(ViewPermision)
              ):(ViewPermision)} />
            <Route path="/admin/grain_harvest_info_pos_harvest_t" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.harvest_grain_t==1)?(GTrazaCPostharvest):(ViewPermision)
              ):(ViewPermision)} />

            <Route path="/admin/grains_traza_documents" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.document_grain_t==1)?(GTrazaDocuments):(ViewPermision)
              ):(ViewPermision)} />

            <Route path="/admin/grains_traza_end" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              (this.state.viewPermissionContentSections?.information_grain_t==1)?(GTrazaFinal):(ViewPermision)
              ):(ViewPermision)} />

            <Route path="/admin/vista_trace_admin_grain_traza" component={(this.state.viewPermissionContent?.indexOf('G')!=-1)?(
              VistaTraceTG
              ):(ViewPermision)} />

            <Route path='/admin/kdes-panel' component={StartPanelKDES} />

            <Route path='/admin/create_harvest_kdes' component={StartHarvestKdes} />

            <Route path='/admin/create_plant_cooling_kdes' component={StartPlantKdes} />
            <Route path='/admin/create_plant_product_kdes' component={StartPlantProductKdes} />
            <Route path='/admin/create_plant_receiver_kdes' component={StartPlantReceiverKdes} />
            <Route path='/admin/create_plant_shipping_kdes' component={StartPlantShippingKdes} />

            <Route path='/admin/create_producer_receiving_kdes' component={StartImportReceiverKdes} />
            <Route path='/admin/create_producer_transformation_kdes' component={StartImportTransformationKdes} />
            <Route path='/admin/create_producer_food_kdes' component={StartImportFoodKdes} />
            <Route path='/admin/create_producer_shipping_kdes' component={StartImportShippingKdes} />

            <Route path='/admin/create_distributor_receiving_kdes' component={StartDistributorReceivingKdes} />
            <Route path='/admin/create_distributor_shipping_kdes' component={StartDistributorShippingKdes} />

            <Route path='/admin/create_establishment_receiving_kdes' component={StartDetailsReceivingKdes} />

            <Route path='/admin/documents_kdes' component={KdesDocuments} />
            <Route path='/admin/finalice_kdes' component={KdesFinal} />

{/*            {data_kdes.map((valk,indk)=>{
              return (<>
                <Route 
                path={valk.path} 
                component={valk.component} 
                key={indk} />
                </>)
            })}*/}

{/*            <Route path={this.routes_kdes_logistict('VF').url} component={this.routes_kdes_logistict('VF').component} />
            <Route path={this.routes_kdes_logistict('V1F').url} component={this.routes_kdes_logistict('V1F').component} />
            <Route path={this.routes_kdes_logistict('V2F').url} component={this.routes_kdes_logistict('V2F').component} />
            <Route path={this.routes_kdes_logistict('V3F').url} component={this.routes_kdes_logistict('V3F').component} />
            <Route path={this.routes_kdes_logistict('V4F').url} component={this.routes_kdes_logistict('V4F').component} />*/}

{/*            {this.routes_kdes('VF','cosecha','/admin/create_harvest_kdes',StartHarvestKdes)}
            {this.routes_kdes('V1F','planta','/admin/create_plant_cooling_kdes',StartPlantKdes)}
            {this.routes_kdes('V2F','importador','/admin/create_producer_receiving_kdes',StartHarvestKdes)}
            {this.routes_kdes('V3F','distribuidor','/admin/create_distributor_receiving_kdes',StartHarvestKdes)}
            {this.routes_kdes('V4F','detallista','/admin/create_establishment_receiving_kdes',StartHarvestKdes)}*/}

            <Route path="/admin/dashboard" component={Dashboard} />
          
          
          <Redirect from="*" to="/admin/home" />
        </Switch>                 
        {/*<AdminFooter />*/}
      </div>
{/*      {this.state.sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}*/}
    </>
  );
 }
}
/* eslint-disable react-hooks/exhaustive-deps */

const Layout = ({ children }) => {
    
    const { layoutConfig, layoutState, setLayoutState, valueGlobal, toast } = useContext(UserContext);
    //const { setRipple } = useContext(PrimeReactContext);
    const topbarRef = useRef(null);
    const sidebarRef = useRef(null);
    const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] = useEventListener({
        type: 'click',
        listener: (event) => {
            const isOutsideClicked = !(
                sidebarRef.current?.isSameNode(event.target) ||
                sidebarRef.current?.contains(event.target) ||
                topbarRef.current?.menubutton?.isSameNode(event.target) ||
                topbarRef.current?.menubutton?.contains(event.target)
            );

            if (isOutsideClicked) {
                hideMenu();
            }
        }
    });

    const pathname = useLocation();
    //const searchParams = useSearchParams();
    useEffect(() => {
        hideMenu();
        hideProfileMenu();
    }, [pathname]);

    const [bindProfileMenuOutsideClickListener, unbindProfileMenuOutsideClickListener] = useEventListener({
        type: 'click',
        listener: (event) => {
            const isOutsideClicked = !(
                topbarRef.current?.topbarmenu?.isSameNode(event.target) ||
                topbarRef.current?.topbarmenu?.contains(event.target) ||
                topbarRef.current?.topbarmenubutton?.isSameNode(event.target) ||
                topbarRef.current?.topbarmenubutton?.contains(event.target)
            );

            if (isOutsideClicked) {
                hideProfileMenu();
            }
        }
    });

    const hideMenu = () => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            overlayMenuActive: false,
            staticMenuMobileActive: false,
            menuHoverActive: false
        }));
        unbindMenuOutsideClickListener();
        unblockBodyScroll();
    };

    const hideProfileMenu = () => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            profileSidebarVisible: false
        }));
        unbindProfileMenuOutsideClickListener();
    };

    const blockBodyScroll = (): void => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = (): void => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    useEffect(() => {
        if (layoutState?.overlayMenuActive || layoutState?.staticMenuMobileActive) {
            bindMenuOutsideClickListener();
        }

        layoutState?.staticMenuMobileActive && blockBodyScroll();
    }, [layoutState?.overlayMenuActive, layoutState?.staticMenuMobileActive]);

    useEffect(() => {
        if (layoutState?.profileSidebarVisible) {
            bindProfileMenuOutsideClickListener();
        }
    }, [layoutState?.profileSidebarVisible]);

    useUnmountEffect(() => {
        unbindMenuOutsideClickListener();
        unbindProfileMenuOutsideClickListener();
    });

    const containerClass = classNames('layout-wrapper', {
        'layout-overlay': layoutConfig?.menuMode === 'overlay',
        'layout-static': layoutConfig?.menuMode === 'static',
        'layout-static-inactive': layoutState?.staticMenuDesktopInactive && layoutConfig?.menuMode === 'static',
        'layout-overlay-active': layoutState?.overlayMenuActive,
        'layout-mobile-active': layoutState?.staticMenuMobileActive,
        'p-input-filled': layoutConfig?.inputStyle === 'filled',
        'p-ripple-disabled': !layoutConfig?.ripple
    });

    return (
        <React.Fragment>
        <Toast ref={toast} />
            <div className={containerClass}>
                <AppTopbar ref={topbarRef} />
                <div ref={sidebarRef} className="layout-sidebar">
                    <AppSidebar />
                </div>
                <div className="layout-main-container">
                    {/*<div className="layout-main">{children}</div>*/}
                    <Admin />
                    <AppFooter />
                </div>
                <div className="layout-mask"></div>
            </div>
        </React.Fragment>
    );
};

const LayoutsAdmin = () => {
    return (
      <DataProvider>
        <Layout />
      </DataProvider>
    );

};


export default LayoutsAdmin;

//export default Admin;
