import React, {useState, useRef} from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import NotificationAlert from "react-notification-alert";
import {register} from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import {useHistory} from "react-router-dom";
import config from "config";
import { Tooltip } from 'primereact/tooltip';
import logo from "assets/img/brand/fda_register.jpeg";

const Register = () => {
  const [focusedName, setfocusedName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedDni, setfocusedDni] = useState(false);
  const [focusedEmpresa, setfocusedEmpresa] = useState(false);
  const [focusedPais, setfocusedPais] = useState(false);
  const [focusedTrazabilidad, setfocusedTrazabilidad] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedConfirmPassword, setfocusedConfirmPassword] = useState(false);

  const [name, setName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [focusedSecondName, setfocusedSecondName] = useState(false);

  const [focusedCodeProd, setfocusedCodeProd] = useState(false);
  const [codeProd, setCodeProd] = useState("");
  const [focusedPhone, setfocusedPhone] = useState(false);

  const [pais, setPais] = useState("");
  const [trazabilidad, setTrazabilidad] = useState("");

  const [trazabilidad_kdes, setTrazabilidadKdes] = useState("0");
  const [focusedTrazabilidadKdes, setfocusedTrazabilidadKdes] = useState(false);

  const [empresa, setEmpresa] = useState("");
  const [areawork, setAreawork] = useState("");
  const [email, setEmail] = useState("");
  const [dni, setDni] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");

  const [checkbox, setCheckbox] = useState(false);
  const [btnD, setBtnD] = useState(true);
  const [error, setError] = useState("");
  const [toastMessage, setToastMessage] = useState("Verifique su correo.");
  const [alert, setalert] = useState(false);
  const { DEMO } = config;
  const [demo, setDemo] = useState(DEMO);
  const notificationAlertRef = useRef(null);
  const history = useHistory();

  const notify = (type, title, message, userID) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const registerUser = async () => {
    setError("Cargando...");
    setBtnD(false);

    if (name=='' || secondName=='' || email=='' || empresa=='' || phone=='' || areawork == '' || pais=='0' || dni=='') {
      setError("Todos los campos son necesarios.");
      setBtnD(true);
      return;
    }
    if (areawork.indexOf('T')!=-1 && (trazabilidad=='0' || trazabilidad=='') ) {
      setError("Seleccione una trazabilidad.");
      setBtnD(true);
      return;
    }else{
      if (areawork.indexOf('T')!=-1 && (trazabilidad_kdes=="0" || trazabilidad_kdes=="")) {
        setError("Seleccione si es Cosecha, Planta, Importador, Distribuidor o Establecimiento.");
        setBtnD(true);
        return;
      }
    }
    if (password !== confirmPassword) {
      setError("las contraseñas no coinciden.");
      setBtnD(true);
      return;
    }
    setError("Espere mientras su cuenta está siendo creada.");
    var set_trz = trazabilidad
    if (areawork.indexOf('T')!=-1) {
      if (trazabilidad.indexOf('VF')!=-1) {
        if (trazabilidad_kdes!="0") {
          if (trazabilidad_kdes.indexOf('VF')!=-1) {
            set_trz = trazabilidad
          }else{
            set_trz = trazabilidad_kdes+'-'+trazabilidad.replaceAll('-VF','').replaceAll('VF-','').replaceAll('VF','')
          }
        }
      }
    }else{
      set_trz=''
    }
     const response = await register(name, secondName, email, empresa, pais, areawork+'|'+set_trz.replaceAll(' ',''), password, 1, phone, "", dni, codeProd)
     .catch(function (message){
       setError(JSON.stringify(message.response.data.errors))
     })
    if (response==undefined) {
      setBtnD(true);
      return;
    }else{
    const {data} = response;
      if (!data.success) {
          setBtnD(true);
          setError(data.msg);
          return;
      }
    }
    setToastMessage("Verifique su correo.");
    setError("");
    setName("");
    setEmail("");
    setDni("");

    setPais("0");
    setEmpresa("");

    setPassword("");
    setConfirmPassword("");
     setBtnD(false);
    notify("success", "¡Cuenta registrado exitosamente!", toastMessage);
    setTimeout(function(){ history.push('/home/validation',{email: email}) }, 3000);
  };
  const listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
    const listCountryCode = CountrysData.sort(listaArray)
    const changeAreaWork = (val) => {
      var data = ''
      if (val.checked) {
        data = areawork+val.value
        //areawork
      }else{
        data = areawork.replace(val.value,'')
      }
      setAreawork(data)
    }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
{/*      <AuthHeader
        title="Crea tu cuenta"
        lead="Escriba sus datos para crear una cuenta y poder utilizar la plataforma."
      />*/}
    {/*lead="Usa este formulario para crear tu cuenta y poder ingresar al sistema."*/}
    {/*<Container className="mt--9 pb-5">*/}
      <Container className="min-vh-100" fluid style={{overflow: 'hidden'}}>
        {/*<Row className="justify-content-center">*/}
        <Row style={{ minHeight: "100vh", paddingTop: '90px' }}>
        <Col md="6" className="justify-content-center align-items-center">
          <Col md={12} className="pb-4 d-flex">
            <div style={{backgroundColor: 'white',width: '227%',height: '155%',position: 'absolute', left: '-10%', bottom: '30%'}}></div>
            <div style={{zIndex: 1,margin:'auto', padding:'1px',borderBottomLeftRadius: '0px',borderTopRightRadius: '0px', borderTopLeftRadius: '20px',borderBottomRightRadius: '20px', width: '60%'}}>
              <div style={{"textAlign": "center"}}>
                <img
                 width="100%"
                 src={logo}
                 alt="Foodchain Trace"
                 style={{borderTopLeftRadius: '20px',borderBottomRightRadius: '20px', padding: '2px',backgroundColor: 'white',borderTopRightRadius: '20px',borderBottomLeftRadius: '20px'}} />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h3 style={{textAlign: 'justify',color:'white'}}>
              Trazabilidad FDA
            </h3>
            <h5 style={{textAlign: 'justify',color:'white'}}>
              El servicio de trazabilidad basado en los requerimientos de la FDA permite a productores, plantas de empaque, distribuidores y toda la cadena de suministro rastrear productos desde la cosecha hasta el supermercado. Es eficiente, reduce costos, cumple con las normativas de la FDA y automatiza los procesos de trazabilidad.
            </h5>
          </Col>
          <Col md={12}>
            <h3 style={{textAlign: 'justify',color:'white'}}>
              Gestion de Cultivo
            </h3>
            <h5 style={{textAlign: 'justify',color:'white'}}>
              El servicio de Gestión de Cultivo permite al productor controlar todo el proceso, desde la preparación del terreno, la aplicación de agroquímicos y fertilizantes, hasta la cosecha y entrega al comprador. Este servicio ayuda al productor a  aumentar la eficiencia y productividad, reduciendo costos y obteniendo mejores precios por sus productos.
            </h5>
          </Col>
        </Col>

          <Col md="6" className="d-flex justify-content-center align-items-center">
            <Card className="bg-secondary border-0">

                <div className="mb-1" style={{textAlign: 'justify',fontWeight: 'bold'}}>
                  Regístrate en la plataforma de Foodchain completando tus datos para crear una cuenta y comenzar a utilizar nuestros servicios.
                </div>
                <div className="text-muted mb-4" style={{textAlign: 'justify',fontWeight: 'bold'}}>
                  Ingrese su correo electronico y contraseña
                </div>
                <Form role="form">
                <Row>
                  
                <Col md={6}>
                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Nombre"
                        type="text"
                        value={name} onChange={e => setName(e.target.value)}
                        onFocus={() => setfocusedName(true)}
                        onBlur={() => setfocusedName(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup
                    className={classnames({
                      focused: focusedSecondName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Apellido"
                        type="text"
                        value={secondName} onChange={e => setSecondName(e.target.value)}
                        onFocus={() => setfocusedSecondName(true)}
                        onBlur={() => setfocusedSecondName(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmpresa,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-shop" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Empresa"
                        type="text"
                        value={empresa} onChange={e => setEmpresa(e.target.value)}
                        onFocus={() => setfocusedEmpresa(true)}
                        onBlur={() => setfocusedEmpresa(false)}
                        name="empresa"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={classnames({
                      focused: focusedDni,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Cédula"
                        type="text"
                        value={dni} onChange={e => setDni(e.target.value)}
                        onFocus={() => setfocusedDni(true)}
                        onBlur={() => setfocusedDni(false)}
                        name="dni"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmpresa,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-phone" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Teléfono"
                        type="text"
                        value={phone} onChange={e => setPhone(e.target.value)}
                        onFocus={() => setfocusedPhone(true)}
                        onBlur={() => setfocusedPhone(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>

                    <label className="text-muted">Servicio a Utilizar</label><br />

                    <input type="checkbox" value="P" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
                    <Tooltip target=".customClassName" mouseTrack mouseTrackLeft={10} />
                    <span className="text-muted customClassName" data-pr-tooltip="Plataforma para llevar la trazabilidad de campo, siembra y cosecha de los productos" data-pr-position="right">Gestion de cultivo</span><br />

{/*                    <input type="checkbox" value="T" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
                    <span className="text-muted customClassName" data-pr-tooltip="Plataforma para llevar registro de trazabilidades" data-pr-position="right">Trazabilidad</span><br />*/}
                    {/*<input type="checkbox" value="C" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}<span>Comercialización: </span>*/}
                  </FormGroup>
                </Col>
                
                  {(areawork.indexOf('T')!=-1)?(<>
                <Col md={6}>
                      <FormGroup
                        className={classnames({
                          focused: focusedTrazabilidad,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-ellipsis-h" />
                            </InputGroupText>
                          </InputGroupAddon>
                      <Input 
                      type="select" 
                      value={trazabilidad} 
                      onChange={e => setTrazabilidad(e.target.value)}
                      onFocus={() => setfocusedTrazabilidad(true)}
                      onBlur={() => setfocusedTrazabilidad(false)}
                            >
                        <option value="0">Trazabilidad</option>
                        <option value="VF">Frutas y Vegetales</option>
{/*                        <option value="M">Carnicos</option>
                        <option value="G">Granos</option>
                        <option value="VF-M">Frutas y Vegetales - Carnicos</option>
                        <option value="VF-G">Frutas y Vegetales - Granos</option>
                        <option value="M-G">Carnicos - Granos</option>
                        <option value="VF-M-G">Frutas y Vegetales - Carnicos - Granos</option>*/}

                      </Input>
                        </InputGroup>
                      </FormGroup>
                      {(trazabilidad.indexOf('VF')!=-1)?(
                          <FormGroup
                            className={classnames({
                              focused: focusedTrazabilidadKdes,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-ellipsis-h" />
                                </InputGroupText>
                              </InputGroupAddon>
                          <Input 
                          type="select" 
                          value={trazabilidad_kdes} 
                          onChange={e => setTrazabilidadKdes(e.target.value)}
                          onFocus={() => setfocusedTrazabilidadKdes(true)}
                          onBlur={() => setfocusedTrazabilidadKdes(false)}
                                >
                            <option value="0">Tipo de Empresa</option>
                            <option value="VF">Productor-Cosecha</option>
                            <option value="V1F">Planta</option>
                            <option value="V2F">Importador</option>
                            <option value="V3F">Distribuidor</option>
                            <option value="V4F">Detallista-Establecimiento</option>
                          </Input>
                            </InputGroup>
                          </FormGroup>
                        ):(null)}
                </Col>
                    </>):(null)}
{/*                  {(areawork.indexOf('P')!=-1)?(<>
                    <FormGroup
                      className={classnames({
                        focused: focusedCodeProd,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-shop" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Código productor"
                          type="text"
                          value={codeProd} onChange={e => setCodeProd(e.target.value)}
                          onFocus={() => setfocusedCodeProd(true)}
                          onBlur={() => setfocusedCodeProd(false)}
                          name="codeProd"
                        />
                      </InputGroup>
                    </FormGroup>
                    </>):(null)}*/}
                <Col md={6}>
                  <FormGroup
                    className={classnames({
                      focused: focusedPais,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-map-big" />
                        </InputGroupText>
                      </InputGroupAddon>
                  <Input 
                  type="select" 
                  value={pais} 
                  onChange={e => setPais(e.target.value)}
                  onFocus={() => setfocusedPais(true)}
                  onBlur={() => setfocusedPais(false)}
                        >
                    <option value="0">Pais</option>
                   {  listCountryCode.map((value,index)=>{
                       return <option value={value.Name}>{value.Name}</option>
                      })
                    }
                  </Input>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Correo electrónico"
                        type="email"
                        value={email} onChange={e => setEmail(e.target.value)}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(false)}
                        name="email"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        value={password} onChange={e => setPassword(e.target.value)}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={classnames({
                      focused: focusedConfirmPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirmar contraseña"
                        type="password"
                        value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}
                        onFocus={() => setfocusedConfirmPassword(true)}
                        onBlur={() => setfocusedConfirmPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                  </Row>
                  {error ?
                    <div className="text-muted font-italic">
                      <small>
                        Mensaje:{" "}
                        <span className="text-red font-weight-700">{error}</span>
                      </small>
                    </div> : null }
                  <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                          checked={checkbox}
                          onChange={() => setCheckbox(!checkbox)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            He leído y acepto los{" "}
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              términos y condiciones
                            </a>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button 
                      className="mt-4" 
                      color="traza-green" 
                      type="button" 
                      disabled={!email || !password || !confirmPassword || !checkbox || !btnD}
                      onClick={registerUser}>
                      Crear cuenta
                    </Button>
                  </div>
                </Form>

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Register;
